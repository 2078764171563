import React from "react";

function Spotlight(props) {
  return (
      <section 
        id={props.id}
        className={[
          "spotlight-component",
          props.theme ? props.theme : "",
        ].join(" ")}>
          <div className="description-wrapper">
            <div className="title-image-wrapper">
              <h1 className="title">{props.title}</h1>
              {props.brandingLogoMobile && (
                <img className="image" src={props.brandingLogoMobile} alt={props.brandingLogoAltMobile} />
              )}
            </div> 
              <p>
                {props.description.map((text, index) => {
                  return (
                    <p key={index}>{text.text}</p>
                  )
                })}
              </p>
              {props.showLinks === true && (
                <div className="link-wrapper">
                {props.appStoreURL && (
                  <a href={props.appStoreURL} target="_blank">
                    <img
                      src={props.appStoreImage}
                      alt={props.appStoreAlt}
                    />
                  </a>
                )}
                {props.googlePlayURL && (
                  <a href={props.googlePlayURL} target="_blank">
                    <img 
                      src={props.googlePlayImage}
                      alt={props.googlePlayAlt} />
                  </a>
                )}
              </div>
              )}
            </div>
              <div className="image-wrapper">
                <img className="image" src={props.imageURL} alt={props.imageAlt} />
              </div>
      </section>
  );
}


export default Spotlight;
