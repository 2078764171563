import React from "react";
import { Brand } from "../common/constants/constants";
import { isSweepsJamba, isSweepsMca, isSweepsMoes } from "../common/helpers/handleSubdomains";
import Hero from "../components/Hero";
import { AppURLData } from "../data/AppUrl.data";
import { LandingData } from "../data/Landing.data";
import { SuccessLandingData } from "../data/SuccessLanding.data";

export default class SweepsSuccessLanding extends React.Component {
  getData() {
    let brand = Brand;

    if (isSweepsMca()) {
      brand = "sweepsmca";
    } else if (isSweepsMoes()) {
      brand = "sweepsmoes"
    } else if (isSweepsJamba()) {
      brand = "sweepsjamba"
    }

    return {
      ...LandingData[brand],
      ...AppURLData[Brand],
      ...SuccessLandingData[brand]
    }
  }

  render() {
    const Data = this.getData();

    return (
      <main className="main-content">
        <Hero
          backgroundImage={Data.heroBackgroundImage}
          showBgOnMobile={Data.showBgOnMobile}
        />
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="text-center">{Data.pageTitle}</h1>
              {Data.pageDescription && (
                <p className="text-large text-center">{Data.pageDescription}</p>
              )}
              {Data.pageDescriptionHTML && (
                <p className="text-large text-center" dangerouslySetInnerHTML={{__html: Data.pageDescriptionHTML}}></p>
              )}
              {/* <h2 className="h1-like text-center mt-medium">{Data.downloadTitle}</h2> */}
              {/* <div className="app-link-component mt-large">
                <a href={Data.appStoreURL} target="_blank">
                  <img
                    src={Data.appStoreImage}
                    alt={Data.appStoreAlt}
                  />
                </a>
                <a href={Data.googlePlayURL} target="_blank">
                  <img
                    src={Data.googlePlayImage}
                    alt={Data.googlePlayAlt} />
                </a>
              </div> */}
              {/* <span className="section-title">{Data.buttonGroupTitle}</span> */}
              {/* <ul className="button-list-component mb-large">
                {Data.orderButton && (
                  <li className="button-wrapper">
                    <span className="info">{Data.orderSubtitle}</span>
                    <a className="primary-button" href={Data.orderButton.URL} target={Data.orderButton.target}>{Data.orderButton.text}
                    </a>
                  </li>
                )}
                
                {Data.locationButton && (
                  <li className="button-wrapper">
                    <span className="info">{Data.locationSubtitle}</span>
                    <a className="secondary-button" href={Data.locationButton.URL} target={Data.locationButton.target}>{Data.locationButton.text}
                    </a>
                  </li>
                )}
              </ul> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <br /> 
              <br />
              <br />
              <br />
              <p className="faint-text">
                {Data.rewardsDisclaimer} 
              </p>
            </div>
          </div>
        </div>
      </main>
    )
  }
}