import { ApiError } from "./exception";

export async function getPartnerInfo(partnerProgram) {
  const response = await fetch(`${process.env.REACT_APP_API}/api/partnerInfo/${partnerProgram}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const obj = await response.json();

  if (response.ok) {
    return obj;
  }

  if (obj.error) {
    throw new ApiError(obj.error);
  }

  throw new Error(obj);
}