

/**
 * Given a joi error object, iterate the error details and extract a field/message dictionary.
 *
 * This function does not handle errors for nested objects.
 */
export function processJoiError(error) {
  const result = {};
  for (const err of error.details) {
    const key = err.path[0];
    result[key] = err.message;
  }
  return result;
}