import React from "react";
import ContentContext from "../common/contexts/content";
import Spotlight from "../components/Spotlight";
import Card from "../components/Card";
import { clearUser } from "../common/user";
import Disclaimer from "../components/Disclaimer";
import Hero from "../components/Hero";
import { isFetchSl } from "../common/helpers/isFetchSl";
import {isFetchMca} from '../common/helpers/isFetchMca';
import Notices from "../components/Notices";
import { isFetchCi } from "../common/helpers/isFetchCi";
import { isFetchAa } from "../common/helpers/isFetchAa";
class SuccessLanding extends React.Component {
  componentDidMount() {
    clearUser();
  }

  render() {
    const Data = this.context.successLandingData;
    const AppURL = this.context.appURLData;

    return (
      <main className="main-content">
        {Data?.showHero ? (
          <Hero
            backgroundImage={Data.heroBackgroundImage}
            showBgOnMobile={Data.showBgOnMobile}
            heroTitle={Data.heroTitle}
            heroDescription={Data.heroDescription}
          />
        ) : (
          <div className="desktop-primary-bg">
            <div className="container">
              <Spotlight
                title={Data.spotlightTitle}
                brandingLogoMobile={Data.spotlightBrandingLogo}
                brandingLogoAltMobile={Data.spotlightBrandingLogoAlt}
                description={Data.spotlightDescription}
                imageURL={Data.spotlightImageURL}
                imageAlt={Data.spotlightImageAlt}
                showLinks={false}
              />
            </div>
          </div>
        )}
        <section className={`${!isFetchSl() && 'mobile-primary-bg'}`}>
          <div className={`container ${isFetchSl() && 'fetchsl-container'}`}>
            <h2 className="section-title">{Data.downloadTitle}</h2>
            <span className="section-subtitle">{Data.downloadSubtitle}</span>
            {Data.howItWorks && (
              <div dangerouslySetInnerHTML={{ __html: Data.howItWorks }}></div>
            )}
            {isFetchSl() || isFetchMca() || isFetchCi() || isFetchAa() ? null :
            <div className="app-link-component">
              <h3 className="section-title">{Data.appTitle}</h3>
              <a href={AppURL.appStoreURL} target="_blank">
                <img
                  src={AppURL.appStoreImage}
                  alt={AppURL.appStoreAlt}
                />
              </a>
              <a href={AppURL.googlePlayURL} target="_blank">
                <img
                  src={AppURL.googlePlayImage}
                  alt={AppURL.googlePlayAlt} />
              </a>
            </div>
            }
            {!Data.hideOrderAndLocationButtons &&
            <>
              <span className="section-title">{Data.buttonGroupTitle}</span>
              <ul className="button-list-component">
                {Data.orderButton && (
                  <li className="button-wrapper">
                    <span className="info">{Data.orderSubtitle}</span>
                    <a className="primary-button" href={Data.orderButton.URL} target={Data.orderButton.target}>{Data.orderButton.text}
                    </a>
                  </li>
                )}
                {Data.locationButton && (
                  <li className="button-wrapper">
                    <span className="info">{Data.locationSubtitle}</span>
                    <a className="secondary-button" href={Data.locationButton.URL} target={Data.locationButton.target}>{Data.locationButton.text}
                    </a>
                  </li>
                )}
              </ul>
            </>
          }
          </div>

          {Data.showNoticesSection && (
          <div className="mobile-primary-bg">
            <section className="pt-medium pb-medium">
              <div className="container">
                <Notices
                  id1={Data.notices1ID}
                  title1={Data.notices1Title}
                  description1={Data.notices1Description}
                  image1URL={Data.notices1ImageURL}
                  image1Alt={Data.notices1ImageAlt}
                  id2={Data.notices2ID}
                  title2={Data.notices2Title}
                  description2={Data.notices2Description}
                  image2URL={Data.notices2ImageURL}
                  image2Alt={Data.notices2ImageAlt}
                />
              </div>
            </section>
          </div>
        )}
        </section>

        <div className={`container ${isFetchSl() && 'fetchsl-benefitsDisclaimer'}`}>
          {Data.showBenefitsSection && (
            <>
              <div className="image-title-component">
                <h2 className="section-title">{Data.benefitsTitle}</h2>
                <div className="image-wrapper">
                  <img className="image" src={Data.benefitsTitleLogo} alt="" />
                </div>
              </div>
              <div className="row">
                {Data.benefits.map((benefit, index) => {
                  return (
                    <div
                      className="col"
                      key={index}
                    >
                      <Card
                        imageURL={benefit.imageURL}
                        imageAlt={benefit.imageAlt}
                        title={benefit.title}
                        description={benefit.description}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <Disclaimer
            disclaimerText={Data.rewardsDisclaimer}
          />
          <div className="container">
          <Disclaimer
            disclaimerText={Data.disclaimerText}
          />
          </div>
          {Data.disclaimerTextAdditional && (
            <div dangerouslySetInnerHTML={{ __html: Data.disclaimerTextAdditional }}></div>
         	)}
        </div>
      </main>
    );
  }
}

SuccessLanding.contextType = ContentContext;

export default SuccessLanding;
