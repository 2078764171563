import "./sass/App.scss";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import React from "react";
import BrandContext from "./common/contexts/brand";
import { LINK_ACCOUNT_URL } from "./common/constants/urls";
import { Brand, DEV_MENU, TRUYO_CDN_URL, TRUYO_TOKENS } from "./common/constants/constants";
import FocusOutlines from "./components/FocusOutlines";
import { isChannelsSubdomain, isSweepsSubdomain } from "./common/helpers/handleSubdomains";
import AppRoutes from "./AppRoutes";
import ContentContext, { createContent } from "./common/contexts/content";

class App extends React.Component {
  componentDidMount() {
    if (typeof TRUYO_TOKENS[Brand] === "undefined") return;

    const script = document.createElement("script");

    script.src = `${TRUYO_CDN_URL}/${TRUYO_TOKENS[Brand]}.js`;
    script.async = true;

    document.head.appendChild(script);
  }

  render() {
    const brand = Brand;
    // assume that the partner is the first url param only if there's no subdomain
    const partner = window.location.pathname.split("/")[1];
    let secondaryTheme;
  
    if (isSweepsSubdomain()) {
      secondaryTheme = "sweeps-theme"
    } else if (isChannelsSubdomain()) {
      secondaryTheme = "channels-theme"
    } else {
      secondaryTheme = `${partner}-theme`
    }

    let content = createContent();

    return (
      <ContentContext.Provider value={content}>
        <BrandContext.Provider value={brand}>
          <FocusOutlines className={`App ${brand}-theme ${secondaryTheme}`}>
            {DEV_MENU && <div className="DEV_MENU">
              <h2>Development Menu</h2>
              <span>
                <br />
              </span>
              <Link to={`/`}>Landing</Link>
              <Link to={`/sign-up`}>Sign up</Link>
              <Link to={`${LINK_ACCOUNT_URL}`}>Link Account</Link>
              <Link to={`/success`}>Success</Link>
              <Link to={`/recovery`}>Password Recovery</Link>
              <Link to={`/404`}>404</Link>
              <Link to={'/reset-landing'}>Reset Landing</Link>
            </div>}

            <Header />
            <AppRoutes />
            <Footer />
          </FocusOutlines>
        </BrandContext.Provider>
      </ContentContext.Provider>
    );
  }
}

export default App;
