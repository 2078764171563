import React from "react";

function Notices(props) {
  return (
      <section 
        id={props.id1}
        className={[
          "notices-component",
          props.theme ? props.theme : "",
        ].join(" ")}>
            <div className="element">
                <div className="image-wrapper">
                    <img className="image" src={props.image1URL} alt={props.image1Alt} />
                </div>
                <div className="title-image-wrapper">
                    <p className="title"><strong>{props.title1}</strong></p>
                    <p dangerouslySetInnerHTML={{ __html: props.description1 }}></p>
                </div> 
            </div>
            <div className="element">
                <div className="image-wrapper">
                    <img className="image" src={props.image2URL} alt={props.image2Alt} />
                </div>
                <div className="title-image-wrapper">
                    <p className="title"><strong>{props.title2}</strong></p>
                    <p dangerouslySetInnerHTML={{ __html: props.description2 }}></p>
                </div>   
            </div>              
      </section>
  );
}


export default Notices;
