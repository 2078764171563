import { ApiError } from "../../api";

const generalErrorMessage = 'Something went wrong. Please try again later.';

/**
 * Process an api error and return an object with general error messages and field error messages.
 */
export function processApiError(err, fieldErrorCodeMessages = {}, errorCodeMessages = {}) {
  const result = {
    generalMessages: [],
    fieldMessages: {},
  };

  // Extract validation error messages from api into the result
  if (err instanceof ApiError && err.error.kind === 'validation') {
    for (const errDetails of err.error.details) {
      // messages without a field are considered general messages
      if (errDetails.field === null) {
        const message = getErrorCodeMessage(errDetails.code, errorCodeMessages);
        result.generalMessages.push(message);
        continue;
      }

      const errorMessages = fieldErrorCodeMessages[errDetails.field];
      // log error in case we can't find the field
      if (!errorMessages) {
        console.error(`Failed to find error codes for field '${errDetails.field}'`);
        continue;
      }
      const message = getErrorCodeMessage(errDetails.code, errorMessages);
      result.fieldMessages[errDetails.field] = message;
    }
    return result;
  }

  // for any other kind of error, log it and return a general error message
  console.error(err);
  result.generalMessages.push(generalErrorMessage);
  return result;
}

/**
 * Retrieve an error message from a dictionary based on an error code.
 *
 * In case a message is not found, log an error for debugging purposes.
 */
function getErrorCodeMessage(errorCode, errorCodeMessages) {
  const message = errorCodeMessages[errorCode];

  // log error in case we can't find the error code
  if (!message) {
    console.error(`Failed to find message for error code '${errorCode}'`);
  }

  return message;
}