export const AppURLData = {
    moes: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/moes-rockin-rewards/id1008719375",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.punchh.moes",
        googlePlayAlt: "Download on Google Play",
    },
    moestmobile: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/moes-rockin-rewards/id1008719375",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.punchh.moes",
        // googlePlayAlt: "Download on Google Play",
    },
    schlotzskys: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/schlotzskys-loyalty/id768163905",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.punchh.schlotzskys",
        googlePlayAlt: "Download on Google Play",
    },
    schlotzskysauntieannes: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/schlotzskys-loyalty/id768163905",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.punchh.schlotzskys",
        googlePlayAlt: "Download on Google Play",
    },
    mcalisters: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/mcalisters-deli/id475738160",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.snapfinger.mobile.mcalisters",
        googlePlayAlt: "Download on Google Play",
    },
    auntieannes: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        googlePlayAlt: "Download on Google Play",
    },
    aamuttggenroll: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    aamuttgg5off25: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    aamuttggbogo: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    aamuttgg5off10: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    aamuttgg10off30: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    aatmobile: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    aatmoaug27: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    jambatmobile: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    schlotzskystmobile: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    mcalisterstmobile: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    cinnabontmobile: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    auntieannesschlotzskys: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        googlePlayAlt: "Download on Google Play",
    },
    auntieannesjamba: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        googlePlayAlt: "Download on Google Play",
    },
    jamba: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/jamba-juice/id932885438",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.olo.jambajuice",
        googlePlayAlt: "Download on Google Play",
    },
    fuelja: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/jamba-juice/id932885438",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.olo.jambajuice",
        googlePlayAlt: "Download on Google Play",
    },
    fueljalto: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/jamba-juice/id932885438",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.olo.jambajuice",
        googlePlayAlt: "Download on Google Play",
    },
    cinnabon: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/cinnabon-rewards/id1532016072",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.cinnfocus.app",
        googlePlayAlt: "Download on Google Play",
    },
    cinnabonmoes: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/cinnabon-rewards/id1532016072",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.cinnfocus.app",
        googlePlayAlt: "Download on Google Play",
    },
    carvel: {
        appStore: "",
        googlePlay: "",
    },
	fetchmca: {
		appStoreImage: "",
		appStoreURL: "",
		appStoreAlt: "",
		googlePlayImage: "",
		googlePlayURL: "",
		googlePlayAlt: "",
    },
	mcalisterscinnabon: {
		appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/mcalisters-deli/id475738160",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.snapfinger.mobile.mcalisters",
        googlePlayAlt: "Download on Google Play",
    },
	fetchsl: {
        appStoreImage: "",
        appStoreURL: "",
        appStoreAlt: "",
        googlePlayImage: "",
        googlePlayURL: "",
        googlePlayAlt: "",
    },
    fetchaa: {
        appStoreImage: "",
        appStoreURL: "",
        appStoreAlt: "",
        googlePlayImage: "",
        googlePlayURL: "",
        googlePlayAlt: "",
    },
	fetchci: {
        appStoreImage: "",
        appStoreURL: "",
        appStoreAlt: "",
        googlePlayImage: "",
        googlePlayURL: "",
        googlePlayAlt: "",
    },
	cinnabonmcalisters: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/cinnabon/id1532016072",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.cinnfocus.app",
        googlePlayAlt: "Download on Google Play",
    },
    moescinnabon: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/moes-rockin-rewards/id1008719375",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.punchh.moes",
        googlePlayAlt: "Download on Google Play",
    },
    channelsaa: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    channelsci: {
        // appStoreImage: "/common/app-store.svg",
        // appStoreURL: "https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914",
        // appStoreAlt: "Download on App Store",
        // googlePlayImage: "/common/google-play.svg",
        // googlePlayURL: "https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks",
        // googlePlayAlt: "Download on Google Play",
    },
    fetchja: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/jamba-juice/id932885438",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.olo.jambajuice",
        googlePlayAlt: "Download on Google Play",
    },
    jambaauntieannes: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/jamba-juice/id932885438",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.olo.jambajuice",
        googlePlayAlt: "Download on Google Play",
    },
    sweepsmoes: {
        appStoreImage: "/common/app-store.svg",
        appStoreURL: "https://apps.apple.com/us/app/moes-rockin-rewards/id1008719375",
        appStoreAlt: "Download on App Store",
        googlePlayImage: "/common/google-play.svg",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.punchh.moes",
        googlePlayAlt: "Download on Google Play",
    },
}