import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LANDING_URL, LINK_ACCOUNT_URL } from "../common/constants/urls";
import { getUser, isUserLinked } from "../common/user";

/**
 * For anonymous users redirect them to the landing page.
 *
 * For logged in users, if they don't have a partner linked, redirect them to the link account page,
 * otherwise let them through.
 */
export class LinkedUserRoute extends React.Component {
  shouldComponentUpdate(nextProps) {
    // only re-render in case the component or path props change since a render of this component
    // will trigger a render of the Route's render prop when on the matching page
    return this.props.component !== nextProps.component
      || this.props.path !== nextProps.path;
  }

  render() {
    const { component, expectedPartner, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={({ match, location, history }) => {
          const user = getUser();

          if (user) {
            if (isUserLinked(user, expectedPartner)) {
              const Component = component;
                return <Component match={match} location={location} history={history} />;
            }

            return <Redirect to={{ pathname: LINK_ACCOUNT_URL }}/>;
          }

          return <Redirect to={{ pathname: LANDING_URL }}/>;
        }}
      />
    );
  }
}