import Joi from "joi";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Brand } from "../common/constants/constants";
import BrandContext from "../common/contexts/brand";
import { isSweepsMca, isSweepsMoes, isSweepsJamba } from "../common/helpers/handleSubdomains";
import Hero from "../components/Hero";
import { LandingData } from "../data/Landing.data";
import { getUser, saveUser } from "../common/user";
import { processApiError } from "../common/helpers/processApiError";
import { processJoiError } from "../common/helpers/processJoiError";
import { Recaptcha } from "../components/Recaptcha";
import { SweepsEntryData } from "../data/SweepsEntry";
import { enterSweeps } from "../api";
import { updatePartner } from "../api/updatePartner";

const recaptchaAction = 'ENTER_SWEEPS';

class SweepsEntry extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    // this.captchaRef = React.createRef();

    let Data = this.getData();
    let formData = {
      ageCheckbox: false,
    };
    if (Data.showTermsCheckbox) {
      formData.communicationCheckbox = false;
    }

    this.state = {
      attemptedSubmit: false,
      name: `${getUser()?.firstName ?? "First Name"} ${getUser()?.lastName ?? "Last Name"}`,
      email: getUser()?.email ?? "Email",
      formData,
      generalErrors: [],
      formErrors: {},
    }
  }

  getData() {
    let brand = Brand;

    if (isSweepsMca()) {
      brand = "sweepsmca";
    } else if (isSweepsMoes()) {
      brand = "sweepsmoes";
    } else if (isSweepsJamba()) {
      brand = "sweepsjamba";
    }

    return {
      ...LandingData[brand],
      ...SweepsEntryData[brand]
    };
  }

  updateFormField(field, value) {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [field]: value,
        },
      }
    });
  }

  updateFormError(field, value) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      }
    });
  }

  handleValueChange = (e) => {
    this.updateFormField(e.target.name, e.target.value);
  };

  handleCheckedChange = (e) => {
    this.updateFormField(e.target.name, e.target.checked);
  };

  handleRecaptchaSuccess = (token) => {
    const field = 'recaptcha';
    this.updateFormField(field, token);
    this.validateFormField(field)
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const Data = this.getData();
    const isValid = this.validateForm();
    this.setState({ attemptedSubmit: true });

    if (isValid) {
      try {
        const { token } = getUser();
        const user = await updatePartner(token, this.props.partner, undefined);
        saveUser(user);
        this.props.history.replace(this.props.successPath);
      } catch (err) {
        const result = processApiError(err, Data.apiFieldErrorMessages);
        this.setState({
          generalErrors: result.generalMessages,
          formErrors: result.fieldMessages,
        });

        // Reset the captcha only in case of error from the backend since on success
        // the page with be changed.
        // this.captchaRef.current.reset();
        // this.updateFormField('recaptcha', '');
      }
    }
  };

  validateFormField = (field) => {
    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    let errorMessage = "";

    if (result.error) {
      for (const error of result.error.details) {
        const key = error.path[0];

        // ignore errors for other fields; this approach is chosen over extracting a sub-schema
        // because we use a joi ref for the confirm password
        if (key === field) {
          errorMessage = error.message;
        }
      }
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    // reset form errors

    this.setState({
      generalErrors: [],
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    if (result.error) {
      const formErrors = processJoiError(result.error);

      const formElements = Array.from(this.formRef.current.querySelectorAll('input, select'));
      let ariaTargets = formElements.map(el => ({
        field: el.name,
        target: el,
      }));

      // manually add recaptcha target since it's managed by a library
      // ariaTargets = [...ariaTargets, {
      //   field: 'recaptcha',
      //   target: this.formRef.current.querySelector('.captcha-wrapper iframe'),
      // }];

      for (const obj of ariaTargets) {
        if (formErrors[obj.field]) {
          obj.target.focus();
          break;
        }
      }
      this.setState({ formErrors });
      return false;
    }
    return true;
  };

  componentDidMount() {
    const Data = this.getData();

    let objSchema = {
      ageCheckbox: Joi.boolean().valid(true).messages({
        'any.only': Data.formErrorMessages.ageCheckbox.required,
      }),
    };

    if (Data.showTermsCheckbox) {
      objSchema.communicationCheckbox = Joi.boolean().valid(true).messages({
        'any.only': Data.formErrorMessages.checkbox.required,
      });
    }

    this.formSchema = Joi.object(objSchema);
  }

  render() {
    const Data = this.getData();

    return (
      <div className="main-content">
        <Hero
          backgroundImage={Data.heroBackgroundImage}
          showBgOnMobile={Data.showBgOnMobile}
        />
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>{Data.title}</h1>
              {Data.description && (
                <p>{Data.description}</p>
              )}
              <form
                ref={this.formRef}
                onSubmit={this.handleSubmit}
                className={[
                  "form-wrapper fill",
                  this.state.attemptedSubmit === true ? "show-errors" : "",
                ].join(" ")}
              >
                <div className="mt-medium">
                  <div className="form-group">
                    <span><strong>Name:</strong> &nbsp;&nbsp;{this.state.name}</span>
                  </div>
                  <div className="">
                    <span><strong>Email:</strong> &nbsp;&nbsp;{this.state.email}</span>
                  </div>
                  <br />
                  <br />
                  <div className="form-group mt-small">
                    <input
                      type="checkbox"
                      id="ageCheckbox"
                      name="ageCheckbox"
                      aria-required="true"
                      aria-invalid={Boolean(this.state.formErrors.ageCheckbox)}
                      aria-describedby="ageCheckboxError"
                      defaultChecked={this.state.ageCheckbox}
                      onChange={this.handleCheckedChange}
                      onBlur={e => this.validateFormField(e.target.name)}
                    />
                    <label htmlFor="ageCheckbox">
                      {/* {Data.agePrefix} */}
                      <span dangerouslySetInnerHTML={{ __html: Data.agePrefix }}></span>
                      {Data.ageLinkURL && (
                        <Link to={Data.ageLinkURL} target="_blank">
                          {Data.ageLinkText}
                        </Link>
                      )}
                    </label>
                    <br />
                    <span id="ageCheckboxError" className="error-label">
                      {this.state.formErrors.ageCheckbox}
                    </span>
                  </div>
                  {
                    Data.showTermsCheckbox && (
                      <div className="form-group mt-small">
                        <input
                          type="checkbox"
                          id="communicationCheckbox"
                          name="communicationCheckbox"
                          aria-required="true"
                          aria-invalid={Boolean(this.state.formErrors.communicationCheckbox)}
                          aria-describedby="communicationCheckboxError"
                          defaultChecked={this.state.communicationCheckbox}
                          onChange={this.handleCheckedChange}
                          onBlur={e => this.validateFormField(e.target.name)}
                        />
                        <label htmlFor="communicationCheckbox">
                          {Data.termsHTML ? (
                            <span dangerouslySetInnerHTML={{ __html: Data.termsHTML }}></span>
                          ) : (
                            <>
                              {Data.termsPrefix}
                              {Data.termsLinkURL && (
                                Data.isTermsExternal ? (
                                  <a
                                    href={Data.termsLinkURL}
                                    target="_blank"
                                  >
                                    {Data.termsLinkText}
                                  </a>
                                ) : (
                                  <Link
                                    to={Data.termsLinkURL}
                                    target="_blank"
                                  >
                                    {Data.termsLinkText}
                                  </Link>
                                )
                              )}.
                            </>
                          )}
                        </label>
                        <br />
                        <span id="communicationCheckboxError" className="error-label">
                          {this.state.formErrors.communicationCheckbox}
                        </span>
                      </div>
                    )
                  }
                </div>
                <div className="row v-align-center mt-large">
                  <div className="col">
                    <div className={[
                      "proceed-wrapper",
                      Data.joinSubtitle ? "" : "h-align-start"
                    ].join(" ")}>
                      <button className="primary-button">
                        {Data.joinButton}
                      </button>
                      {Data.joinSubtitle && (
                        <span>
                          {Data.joinSubtitle} <br />{Data.joinSubtitleSecond}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <br />
              <br />
              <br />
              <br />
              <p className="faint-text">
                {Data.rewardsDisclaimer}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


SweepsEntry.contextType = BrandContext;

export default withRouter(SweepsEntry);