import Joi from "joi";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { login } from "../api";
import ContentContext from "../common/contexts/content";
import { processApiError } from "../common/helpers/processApiError";
import { processJoiError } from "../common/helpers/processJoiError";
import { saveUser } from "../common/user";
import { Recaptcha } from "./Recaptcha";

const recaptchaAction = 'LOGIN';

class SignInForm extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.captchaRef = React.createRef();
    this.state = {
      attemptedSubmit: false,
      isSubmitting: false,
      formData: {
        email: "",
        password: "",
        recaptcha: "",

      },
      generalErrors: [],
      formErrors: {},
    };
  }

  componentDidMount() {
    const Data = this.context.signInData;
    this.formSchema = Joi.object({
      email: Joi.string().required().email({ minDomainSegments: 2, tlds: false }).messages({
        'string.email': Data.formErrorMessages.email.invalid,
        'string.empty': Data.formErrorMessages.email.required,
      }),
      password: Joi.string().required().messages({
        'string.empty': Data.formErrorMessages.password.required,
      }),
      recaptcha: Joi.string().required().messages({
        'string.empty': Data.formErrorMessages.recaptcha.required,
      }),
    });
  }

  updateFormField(field, value) {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [field]: value,
        },
      }
    });
  }

  updateFormError(field, value) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      }
    });
  }

  handleValueChange = (e) => {
    this.updateFormField(e.target.name, e.target.value);
  };

  handleRecaptchaSuccess = (token) => {
    const field = 'recaptcha';
    this.updateFormField(field, token);
    this.validateFormField(field)
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = this.validateForm();
    this.setState({ attemptedSubmit: true, isSubmitting: true });

    if (isValid) {
      try {
        const user = await login(this.state.formData, this.state.formData.recaptcha, recaptchaAction);
        saveUser(user);

        if (this.props.onLoginSuccess) {
          this.props.onLoginSuccess(user);
        }
      this.setState({ isSubmitting: false });
      } catch (err) {
        const Data = this.context.signInData;
        const result = processApiError(err, Data.apiFieldErrorMessages, Data.apiErrorMessages);
        this.setState({
          generalErrors: result.generalMessages,
          formErrors: result.fieldMessages,
          isSubmitting: false,
        });

        // Reset the captcha only in case of error from the backend since on success
        // the page with be changed.

        this.captchaRef.current.reset();
        this.updateFormField('recaptcha', '');
      }
    } else {
      this.setState({ isSubmitting: false });
    }
  };

  validateFormField = field => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });


    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      generalErrors: [],
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    if (result.error) {
      const formErrors = processJoiError(result.error);

      const formElements = Array.from(this.formRef.current.querySelectorAll('input, select'));
      let ariaTargets = formElements.map(el => ({
        field: el.name,
        target: el,
      }));

      // manually add recaptcha target since it's managed by a library
      ariaTargets = [...ariaTargets, {
        field: 'recaptcha',
        target: this.formRef.current.querySelector('.captcha-wrapper iframe'),
      }];

      for (const obj of ariaTargets) {
        if (formErrors[obj.field]) {
          obj.target.focus();
          break;
        }
      }

      this.setState({ formErrors });
      return false;
    }
    return true;
  };

  render() {
    const Data = this.context.signInData;

    return (
      <>
        {Data.signupInfo && (
          <>
            <br />
            <br />
            <br />
            <br />
            <p className="text-bold">{Data.signupInfo}</p>
          </>
        )}
        {Data.formTitle && (
          <h3 className={Data.formTitleClass}>{Data.formTitle}</h3>
        )}
        {Data.formDescription && (
          <p className="info-wrapper">
          {Data.formDescription} {Data.signUpLink && (
            <Link to={Data.signUpLink.href}>{Data.signUpLink.text}</Link>
          )}
        </p>
        )}
        <form
          ref={this.formRef}
          onSubmit={this.handleSubmit}
          className={[
            "form-wrapper",
            this.state.attemptedSubmit === true ? "show-errors" : "",
          ].join(" ")}
          id="loginForm"
        >
          {Data.signInMessage && (
          <h3 className={Data.signInMessageClass}>{Data.signInMessage}</h3>
          )}
          <div className="row">
            <div className="col">
              <div className="error-wrapper">
                {this.state.generalErrors.map((message, index) => {
                  return <span key={index} className="error">{message}</span>
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="email">{Data.emailLabel}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={[
                    this.state.formErrors.email ? 'invalid' : ''
                  ]}
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.email)}
                  aria-describedby="emailError"
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="emailError" className="error-label">
                  {this.state.formErrors.email}
                </span>
              </div>
            </div>
            {!Data?.isFullColumn && (
              <div className="col">
              </div>
            )}
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="password">{Data.passwordLabel}</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  className={[
                    this.state.formErrors.password ? 'invalid' : ''
                  ]}
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.password)}
                  aria-describedby="passwordError"
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="passwordError" className="error-label">
                  {this.state.formErrors.password}
                </span>
              </div>
              {Data.recoveryURLText && (
                <Link className="primary-link" to={Data.recoveryURL ? Data.recoveryURL : "/recovery"}>
                  {Data.recoveryURLText}
                </Link>
              )}
            </div>
            {!Data?.isFullColumn && (
              <div className="col">
              </div>
            )}
          </div>
          <div className="row mt-large">
            <div className="col">
              <div className="captcha-wrapper">
                <Recaptcha action={recaptchaAction} onSuccess={this.handleRecaptchaSuccess} ref={this.captchaRef} />
              </div>
              <span className="error-label">
                {this.state.formErrors.recaptcha}
              </span>
            </div>
            <div className="col">
              <div className={[
                "button-wrapper",
                Data?.isFullColumn ? "h-align-end" : ""
              ].join(" ")}>
                <button disabled={this.state.isSubmitting}  className="primary-button">
                  {Data.signInButton}
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    )
  }
}

SignInForm.contextType = ContentContext;

export default withRouter(SignInForm);
