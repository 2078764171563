export const LinkingData = {
    moes: {
        pageTitle: "OK {0} - Let's Link Your Accounts",
        partnerProgram: "Partner Program*",
        partnerDefaultOption: "Select One",
        partnerOptions: [
            {
                value: "partner1",
                optionText: "AARP member",
            },
        ],
        memberIDLabel: "Member ID*",
        linkAccountInfo: "By linking your account, you will become eligible to receive any additional benefits that are associated with selected parternship program.",
        linkAccountButton: "Link My Account",
        finishSubtitle: "",
        finishButton: "",

        formErrorMessages: {
            partnerProgram: {
                required: "Partner program is required",
            },
            memberId: {
                required:  "Member ID is required",
                invalid: "Enter a valid member ID"
            },
        },
        apiFieldErrorMessages: {
            memberId: {
                invalid: "Member ID is invalid"
            }
        },
    },
    schlotzskys: {
        pageTitle: "OK {0} - Let's Link Your Accounts",
        partnerProgram: "Partner Program*",
        partnerDefaultOption: "Select One",
        partnerOptions: [
            {
                value: "partner1",
                optionText: "AARP member",
            },
        ],
        memberIDLabel: "Member ID*",
        linkAccountInfo: "By linking your account, you will become eligible to receive any additional benefits that are associated with selected parternship program.",
        linkAccountButton: "Link My Account",
        finishSubtitle: "",
        finishButton: "",

        formErrorMessages: {
            partnerProgram: {
                required: "Partner program is required",
            },
            memberId: {
                required:  "Member ID is required",
                invalid: "Enter a valid member ID"
            },
        },
        apiFieldErrorMessages: {
            memberId: {
                invalid: "Member ID is invalid"
            }
        },
    },
    mcalisters: {
        pageTitle: "OK {0} - Let's Link Your Accounts",
        partnerProgram: "Partner Program*",
        partnerDefaultOption: "Select One",
        partnerOptions: [
            {
                value: "partner1",
                optionText: "AARP member",
            },
        ],
        memberIDLabel: "Member ID*",
        linkAccountInfo: "By linking your account, you will become eligible to receive any additional benefits that are associated with selected parternship program.",
        linkAccountButton: "Link My Account",
        finishSubtitle: "",
        finishButton: "",

        formErrorMessages: {
            partnerProgram: {
                required: "Partner program is required",
            },
            memberId: {
                required:  "Member ID is required",
                invalid: "Enter a valid member ID"
            },
        },
        apiFieldErrorMessages: {
            memberId: {
                invalid: "Member ID is invalid"
            }
        },
    },
    auntieannes: {
        pageTitle: "OK {0} - Let's Link Your Accounts",
        partnerProgram: "Partner Program*",
        partnerDefaultOption: "Select One",
        partnerOptions: [
            {
                value: "partner1",
                optionText: "AARP member",
            },
        ],
        memberIDLabel: "Member ID*",
        linkAccountInfo: "By linking your account, you will become eligible to receive any additional benefits that are associated with selected parternship program.",
        linkAccountButton: "Link My Account",
        finishSubtitle: "",
        finishButton: "",

        formErrorMessages: {
            partnerProgram: {
                required: "Partner program is required",
            },
            memberId: {
                required:  "Member ID is required",
                invalid: "Enter a valid member ID"
            },
        },
        apiFieldErrorMessages: {
            memberId: {
                invalid: "Member ID is invalid"
            }
        },
    },
    jamba: {
        pageTitle: "OK {0} - Let's Link Your Accounts",
        partnerProgram: "Partner Program*",
        partnerDefaultOption: "Select One",
        partnerOptions: [
            {
                value: "partner1",
                optionText: "AARP member",
            },
        ],
        memberIDLabel: "Member ID*",
        linkAccountInfo: "By linking your account, you will become eligible to receive any additional benefits that are associated with selected parternship program.",
        linkAccountButton: "Link My Account",
        finishSubtitle: "",
        finishButton: "",

        formErrorMessages: {
            partnerProgram: {
                required: "Partner program is required",
            },
            memberId: {
                required:  "Member ID is required",
                invalid: "Enter a valid member ID"
            },
        },
        apiFieldErrorMessages: {
            memberId: {
                invalid: "Member ID is invalid"
            }
        },
    },
    cinnabon: {
        pageTitle: "OK {0} - Let's Link Your Accounts",
        partnerProgram: "Partner Program*",
        partnerDefaultOption: "Select One",
        partnerOptions: [
            {
                value: "partner1",
                optionText: "AARP member",
            },
        ],
        memberIDLabel: "Member ID*",
        linkAccountInfo: "By linking your account, you will become eligible to receive any additional benefits that are associated with selected parternship program.",
        linkAccountButton: "Link My Account",
        finishSubtitle: "",
        finishButton: "",

        formErrorMessages: {
            partnerProgram: {
                required: "Partner program is required",
            },
            memberId: {
                required:  "Member ID is required",
                invalid: "Enter a valid member ID"
            },
        },
        apiFieldErrorMessages: {
            memberId: {
                invalid: "Member ID is invalid"
            }
        },
    },
    carvel: {
        pageTitle: "OK {0} - Let's Do Step 2 and Link Your Accounts",
        partnerProgram: "Partner Program*",
        partnerDefaultOption: "Select One",
        partnerOptions: [
            {
                value: "partner1",
                optionText: "AARP member",
            },
        ],
        memberIDLabel: "Member ID*",
        linkAccountInfo: "By linking your account, you will become eligible to receive any additional benefits that are associated with selected parternship program.",
        linkAccountButton: "Link My Account",
        finishSubtitle: "Can't finish this step now?",
        finishButton: "Finish Later",

        formErrorMessages: {
            partnerProgram: {
                required: "Partner program is required",
            },
            memberId: {
                required:  "Member ID is required",
                invalid: "Enter a valid member ID"
            },
        },
        apiFieldErrorMessages: {
            memberId: {
                invalid: "Member ID is invalid"
            }
        },
    },
}