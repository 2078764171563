
/**
 * Replace numbered placeholders in first parameter with the following parameters.
 * 
 * Example:
 * Interpolate("Lorem {1} ipsum {0} wtv {2}", 'first', 'second', 'third')
 * // "Lorem second ipsum first wtv third"
 * 
 */
export const Interpolate = function(format) {
  var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function(match, number) { 
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
};