import { Route, Redirect } from "react-router-dom";
import { getUser } from "../common/user";

/**
 * Redirect logged in users to provided path.
 */
export function AnonymousRoute({ redirectPath, children, component, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ match, location, history }) => {
        const user = getUser();

        if (!user) {
          // render `component` or `children`
          if (component) {
            const Component = component;
            return <Component match={match} location={location} history={history} />;
          } else {
            return children;
          }
        }

        return <Redirect to={{ pathname: redirectPath }}/>;
      }}
    />
  );
}