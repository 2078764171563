import React from "react";
import { Brand } from "../common/constants/constants";
import { isSweepsJamba, isSweepsMca, isSweepsMoes } from "../common/helpers/handleSubdomains";
import Hero from "../components/Hero";
import { LandingData } from "../data/Landing.data";
import { SweepsTCData } from "../data/SweepsTCData.data";

export default class SweepsTerms extends React.Component {
  getData() {
    let brand = Brand;

    if (isSweepsMca()) {
      brand = "sweepsmca";
    } else if (isSweepsMoes()) {
      brand = "sweepsmoes";
    } else if (isSweepsJamba()) {
      brand = "sweepsjamba";
    }

    return {
      ...SweepsTCData[brand],
      ...LandingData[brand]
    }
  }

  render() {
    const Data = this.getData();

    return (
      <div className="main-content">
        <Hero
          backgroundImage={Data.heroBackgroundImage}
          showBgOnMobile={Data.showBgOnMobile}
        />
        <div className="container">
          <h1 className="text-center" dangerouslySetInnerHTML={{ __html: Data.title }}></h1>
          <div className="rich-text mt-large" dangerouslySetInnerHTML={{__html: Data.content}}></div>
        </div>
      </div>
    )
  }
}