import React from "react";
import { AppURLData } from "../../data/AppUrl.data";
import { LandingData } from "../../data/Landing.data";
import { SuccessLandingData } from "../../data/SuccessLanding.data";
import { RecoveryData } from "../../data/Recovery.data";
import { HeaderData } from "../../data/Header.data";
import { FooterData } from "../../data/Footer.data";
import { SignUpData } from "../../data/SignUp.data";
import { SignInData } from "../../data/SignIn.data";
import { NotFoundData } from "../../data/NotFound.data";

import { Brand, CROSS_SELL_BRANDS, HAS_FETCH_PARTNER, HAS_FUEL_PARTNER, POLL_ID } from "../constants/constants";
import { getSubdomain, isChannelsSubdomain, isSweepsSubdomain } from "../helpers/handleSubdomains";

export function createContent() {
  let dataId = Brand;

  if (isSweepsSubdomain()) {
    dataId = getSweepsDataId();
  } else if (isChannelsSubdomain()) {
    dataId = getChannelsDataId();
  } else if (getSubdomain().includes("partners")) {
    if (HAS_FETCH_PARTNER && window.location.pathname.startsWith("/fetch")) {
      dataId = getFetchDataId();
    } else if (HAS_FUEL_PARTNER && window.location.pathname.startsWith("/fuelrewardslto")) {
      dataId = getFuelRewardsLtoDataId();
    } else if (HAS_FUEL_PARTNER && window.location.pathname.startsWith("/fuelrewards")) {
      dataId = getFuelRewardsDataId();
    } else if (CROSS_SELL_BRANDS && window.location.pathname.startsWith("/cs")) {
      dataId = getCrossSellDataId();
    } else if (Brand === "auntieannes" && window.location.pathname.startsWith("/mut")) {
      dataId = getAAMutDataId();
    } else if (Brand === "auntieannes" && window.location.pathname.startsWith("/tmobiletuesdaysaug27")) {
      dataId = 'aatmoaug27';
    } else if (Brand === "auntieannes" && window.location.pathname.startsWith("/tmobile")) {
      dataId = getTmobileDataId();
    } else if (Brand === "moes" && window.location.pathname.startsWith("/tmobile")) {
      dataId = getTmobileDataId();
    } 
    else if (Brand === "schlotzskys" && window.location.pathname.startsWith("/tmobile")) {
      dataId = getTmobileDataId();
    } else if (Brand === "mcalisters" && window.location.pathname.startsWith("/tmobile")) {
      dataId = getTmobileDataId();
    } else if (Brand === "cinnabon" && window.location.pathname.startsWith("/tmobile")) {
      dataId = getTmobileDataId();
    } else if (Brand === "jamba" && window.location.pathname.startsWith("/tmobile")) {
      dataId = getTmobileDataId();
    } else {
      dataId = Brand;
    }
  } else if (POLL_ID && Brand === "carvel") {
    dataId = "carvel"; // Carvel (special snowflake)
  }

  let content = {
    headerData: HeaderData[dataId],
    appURLData: AppURLData[dataId],
    landingData: LandingData[dataId],
    successLandingData: SuccessLandingData[dataId],
    recoveryData: RecoveryData[dataId],
    footerData: FooterData[dataId],
    signUpData: SignUpData[dataId],
    signInData: SignInData[dataId],
    notFoundData: NotFoundData[dataId],
  };

  return content;
}

const Context = React.createContext();

export default Context;

function getSweepsDataId() {
  switch (Brand) {
    case 'moes': return "sweepsmoes";
    case 'mcalisters': return "sweepsmca";
    case 'jamba': return "sweepsjamba";
  }
}

function getFetchDataId() {
  switch (Brand) {
    case 'mcalisters': return "fetchmca";
    case 'schlotzskys': return "fetchsl";
    case 'cinnabon': return "fetchci";
    case 'jamba': return "fetchja";
    case 'auntieannes': return "fetchaa";
  }
}

function getChannelsDataId() {
  switch (Brand) {
    case 'auntieannes': return "channelsaa";
    case 'cinnabon': return "channelsci";
  }
}

function getFuelRewardsDataId() {
  switch (Brand) {
    case 'jamba': return "fuelja";
  }
}

function getFuelRewardsLtoDataId() {
  switch (Brand) {
    case 'jamba': return "fueljalto";
  }
}

function getCrossSellDataId() {
  const segments = window.location.pathname.split('/').filter(s => s.length);
  const path = `/${segments[0]}`;

  switch (Brand) {
    case 'jamba': switch (path) {
      case '/csjamaun': return "jambaauntieannes";
    }
    case 'auntieannes': switch (path) {
      case '/csaunsch': return "auntieannesschlotzskys";
      case '/csaunjam': return "auntieannesjamba";
    }
    case 'cinnabon': switch (path) {
      case '/cscinmca': return "cinnabonmcalisters";
      case '/cscinmoe': return "cinnabonmoes";
    }
    case 'mcalisters': switch (path) {
      case '/csmcacin': return "mcalisterscinnabon";
    }
    case 'moes': switch (path) {
      case '/csmoecin': return "moescinnabon";
    }
    case 'schlotzskys': switch (path) {
      case '/csschaun': return "schlotzskysauntieannes";
    }
  }
}

function getAAMutDataId() {
  const segments = window.location.pathname.split('/').filter(s => s.length);
  const path = `/${segments[0]}`;

  switch (Brand) {
    case 'auntieannes': switch (path) {
      case '/mutggenroll': return "aamuttggenroll";
      case '/mutgg5off25': return "aamuttgg5off25";
      case '/mutggbogo6m29p02s': return "aamuttggbogo";
      case '/mut5off1004a03t04s': return "aamuttgg5off10";
      case '/mutgg10off3012m05g05s': return "aamuttgg10off30";
    }
  }
}

function getTmobileDataId() {
  switch (Brand) {
    case 'auntieannes': return "aatmobile";
    case 'schlotzskys': return "schlotzskystmobile";
    case 'mcalisters': return "mcalisterstmobile";
    case 'cinnabon': return "cinnabontmobile";
    case 'jamba': return "jambatmobile";
    case 'moes': return "moestmobile";
  }
}