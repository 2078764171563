import { Brand, HAS_FUEL_PARTNER } from "../constants/constants";
import { hasPartnerSubdomain } from "./handleSubdomains";

export function isFuelJa() {
	// check if has subdomains
	if (hasPartnerSubdomain()) return false;

	return HAS_FUEL_PARTNER && Brand === "jamba" && window.location.pathname.startsWith("/fuelrewards");
}

export function isFuelJaLto() {
	// check if has subdomains
	if (hasPartnerSubdomain()) return false;

	return HAS_FUEL_PARTNER && Brand === "jamba" && window.location.pathname.startsWith("/fuelrewardslto");
}