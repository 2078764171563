import React from "react";
import { Link } from "react-router-dom";
import { LINK_ACCOUNT_URL } from "../common/constants/urls";
import { Brand } from "../common/constants/constants";
let brand = Brand;
let backgroundImageIs;
function Hero(props) {
  if (brand === "carvel") {
    backgroundImageIs = `url(${props.backgroundImage}), url(/carvel/bg2-fallback.png)`
  } else {
    backgroundImageIs = `url(${props.backgroundImage})`
  }
  return (
    <section
      className={[
        "hero-component",
        props.showBgOnMobile ? "mobile" : "",
        props.heroCustomClass ? props.heroCustomClass : ""
      ].join(" ")}
      style={{
        backgroundImage: backgroundImageIs,
      }}
    >
      <div className="container">
        {
          props.heroCustomHTML && (
            <div className={props.heroCustomHTMLClass} dangerouslySetInnerHTML={{ __html: props.heroCustomHTML }}></div>
          )
        }
        {(props.heroTitle || props.heroTitleHTML) && (
          // assume that having a hero title means we have content
          // change the logic if it won't be applicable anymore
          // maybe a flag in the content data like: "show-content: true / false"
          <div className="content-wrapper">
            <div className="title-image-wrapper">
              {props.heroTitleHTML ? (
                <h1 className="title" dangerouslySetInnerHTML={{ __html: props.heroTitleHTML }}></h1>
              ) : (
                <h1 className="title">{props.heroTitle}</h1>
              )}
              {props.brandingLogoMobile && (
                <img className="image" src={props.brandingLogoMobile} alt={props.brandingLogoAltMobile} />
              )}
            </div>
            {props.heroDescription && (
              <p className="description">{props.heroDescription}</p>
            )}
            {/* {props.heroNote && ( */}
              <p className="note">
                {props.heroNote}
              </p>
            {/* )} */}
            <div className="link-group">
              {props.signUpButton && (
                <div className="link-wrapper">
                  <span className="info">{props.signUpSubtitle}</span>
                  {props.signUpUrl?.startsWith("#") ? (
                    <a
                      href={props.signUpUrl}
                      className="primary-button"
                    >
                      {props.signUpButton}
                    </a>
                  ) : (
                    <Link
                      className="primary-button"
                      to={props.signUpUrl || `/sign-up`}
                    >
                      {props.signUpButton}
                    </Link>
                  )}
                </div>
              )}
              {props.linkAccountButton && (
                <div className="link-wrapper">
                  <span className="info">{props.linkAccountSubtitle}</span>
                  <Link
                    className="secondary-button"
                    to={props.linkAccountButtonURL || LINK_ACCOUNT_URL}
                  >
                    {props.linkAccountButton}
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
        {props.brandingLogoDesktop && (
          <div className="image-wrapper">
            <img className="image" src={props.brandingLogoDesktop} alt={props.heroBrandingLogoAltDesktop} />
          </div>
        )}
      </div>
    </section>
  );
}

export default Hero;
