import { ApiError } from "./exception";

export async function login(input, recaptchaToken, recaptchaAction) {
  const response = await fetch(`${process.env.REACT_APP_API}/api/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: input.email,
      password: input.password,
      recaptcha: {
        token: recaptchaToken,
        action: recaptchaAction,
      }
    })
  });

  const obj = await response.json();

  if (response.ok) {
    return obj;
  }

  if (obj.error) {
    throw new ApiError(obj.error);
  }

  throw new Error(obj);
}