import React from "react";
import ContentContext from "../common/contexts/content";
import { RecoveryData } from "../data/Recovery.data";
import Joi from 'joi';
import { recoverPassword } from "../api/recoverPassword";
import { processApiError } from "../common/helpers/processApiError";
import { processJoiError } from "../common/helpers/processJoiError";
import { RESET_LANDING_URL } from "../common/constants/urls";
import { Brand } from "../common/constants/constants";
import { Recaptcha } from "../components/Recaptcha";

const recaptchaAction = 'FORGOT_PASSWORD';

class Recovery extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.captchaRef = React.createRef();
    this.state = {
      attemptedSubmit: false,
      formData: {
        email: "",
        recaptcha: "",
      },
      generalErrors: [],
      formErrors: {},
    };
  }

  componentDidMount() {
    const Data = this.context.recoveryData;
    this.formSchema = Joi.object({
      email: Joi.string().required().email({ minDomainSegments: 2, tlds: false}).messages({
        'string.empty': Data.formErrorMessages.email.required,
        'string.email': Data.formErrorMessages.email.invalid,
      }),
      recaptcha: Joi.string().required().messages({
        'string.empty': Data.formErrorMessages.recaptcha.required,
      }),
    });
  }

  handleValueChange = (e) => {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [e.target.name]: e.target.value,
        },
      }
    });
  };

  updateFormField(field, value) {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [field]: value,
        },
      }
    });
  }

  updateFormError(field, value) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      }
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = this.validateForm();
    this.setState({ attemptedSubmit: true });

    if (isValid) {
      try {
        await recoverPassword(this.state.formData.email, this.state.formData.recaptcha, recaptchaAction);

        const Data = this.context.recoveryData;
        this.props.history.replace(Data.resetLandingURL || RESET_LANDING_URL);
      } catch (err) {
        const result = processApiError(err);
        this.setState({
          generalErrors: result.generalMessages,
        });

        // Reset the captcha only in case of error from the backend since on success
        // the page with be changed.
        this.captchaRef.current.reset();
        this.updateFormField('recaptcha', '');
      }
    }
  };

  validateFormField = field => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      generalErrors: [],
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    if (result.error) {
      const formErrors = processJoiError(result.error);

      const formElements = Array.from(this.formRef.current.querySelectorAll('input, select'));
      let ariaTargets = formElements.map(el => ({
        field: el.name,
        target: el,
      }));

      // manually add recaptcha target since it's managed by a library
      ariaTargets = [...ariaTargets, {
        field: 'recaptcha',
        target: this.formRef.current.querySelector('.captcha-wrapper iframe'),
      }];

      for (const obj of ariaTargets) {
        if (formErrors[obj.field]) {
          obj.target.focus();
          break;
        }
      }

      this.setState({ formErrors });
      return false;
    }
    return true;
  };

  handleRecaptchaSuccess = (token) => {
    const field = 'recaptcha';
    this.updateFormField(field, token);
    this.validateFormField(field);
  };

  render() {
    const Data = this.context.recoveryData;

    return (
      <main className="main-content">
        <section className="container">
          <div className="row">
            <div className="col">
              <h1>{Data.pageTitle}</h1>
            </div>
          </div>
          <form
            ref={this.formRef}
            onSubmit={this.handleSubmit}
            className={[
              "form-wrapper",
              this.state.attemptedSubmit === true ? "show-errors" : "",
            ].join(" ")}
            id="recoveryForm"
          >
            <div className="row">
              <div className="col">
                <div className="error-wrapper">
                  {this.state.generalErrors.map((message, index) => {
                    return <span key={index} className="error">{message}</span>
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="email">{Data.emailLabel}</label>
                  <br />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    aria-required="true"
                    aria-invalid={Boolean(this.state.formErrors.email)}
                    aria-describedby="emailError"
                    onChange={this.handleValueChange}
                    onBlur={e => this.validateFormField(e.target.name)}
                  />
                  <span id="emailError" className="error-label">
                    {this.state.formErrors.email}
                  </span>
                </div>
              </div>
              <div className="col"></div>
            </div>
            <div className="row">
              <div className="col">
                <div className="captcha-wrapper">
                  <Recaptcha action={recaptchaAction} onSuccess={this.handleRecaptchaSuccess} ref={this.captchaRef} />
                </div>
                <span className="error-label">
                  {this.state.formErrors.recaptcha}
                </span>
              </div>
              <div className="col">
                <button className="primary-button">
                  {Data.resetButton}
                </button>
              </div>
            </div>
          </form>
        </section>
      </main>
    );
  }
}

Recovery.contextType = ContentContext;

export default Recovery;
