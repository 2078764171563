import React from "react";
import { Link } from "react-router-dom";

function Card(props) {
  return (
    <div 
      className={[
        "card-component",
        props.theme ? props.theme : "",
      ].join(" ")}>
      {props.imageURL && (
        <div className="image-wrapper">
          <img src={props.imageURL} alt={props.imageAlt} />
        </div>
      )}
      <div className="content-wrapper">
        <h3 className="title">{props.title}</h3>
        {props.imageContentUrl && (
          <div className="image-wrapper">
            <img src={props.imageContentUrl} alt={props.imageContentAlt} />
          </div>
        )}
        {props.children}
        <p className="description">
          {props.href && (
            <Link to={props.href}>{props.link}</Link>
            )}
            {props.description}
        </p>
        {props.note && (
          <small className="note">
            {props.note}
          </small>
        )}
      </div>
    </div>
  );
}

export default Card;
