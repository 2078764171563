import React from "react";
import { Link } from "react-router-dom";
import ContentContext from "../common/contexts/content";

class NotFound extends React.Component {
  render() {
    const Data = this.context.notFoundData;

  return (
      <main className="main-content">
        <section className="container">
          <div className="row">
            <div className="col">
              <h1>{Data.pageTitle}</h1>
              <p>Sorry we can't seem to find the page you are trying to access.<br />You can access the <Link to="/">homepage</Link> or try a different link.</p>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

NotFound.contextType = ContentContext;

export default NotFound;