export const SignInData = {
  moes: {
    pageTitle: "Step 1 - Sign In Moe Rewards!",
    signInMessage: "Sign Into for Moe Rewards​",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  moestmobile: {
    pageTitle: "Step 1 - Sign In Moe Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    recoveryURL: "/tmobiletuesdaysnov12/recovery",
    formTitle: "SIGN INTO MOE REWARDS",
    formTitleClass: "form-title",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  schlotzskys: {
    pageTitle: "Step 1 - Sign In Schlotzsky's Rewards!",
    signInMessage: "Sign Into for Schlotzsky’s Rewards​",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  cinnabonmcalisters: {
    pageTitle: "Step 1 - Sign Into McAlister’s Rewards!",
    emailLabel: "Email Address*",
    formTitle: "Sign In",
    formTitleClass: "form-title",
    passwordLabel: "Password*",
    recoveryURL: "/cscinmca/recovery",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  cinnabonmoes: {
    pageTitle: "Step 1 - Sign Into McAlister’s Rewards!",
    emailLabel: "Email Address*",
    formTitle: "Sign In",
    formTitleClass: "form-title",
    passwordLabel: "Password*",
    recoveryURL: "/cscinmoe/recovery",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  moescinnabon: {
    pageTitle: "Step 1 - Sign Into McAlister’s Rewards!",
    emailLabel: "Email Address*",
    formTitle: "Sign In",
    formTitleClass: "form-title",
    passwordLabel: "Password*",
    recoveryURL: "/csmoecin/recovery",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  mcalisters: {
    pageTitle: "Step 1 - Sign Into McAlister’s Rewards!",
    emailLabel: "Email Address*",
    signInMessage: "Sign into McAlister's Rewards​",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  mcalisterscinnabon: {
    pageTitle: "Step 1 - Sign Into McAlister’s Rewards!",
    emailLabel: "Email Address*",
    formTitle: "Sign In",
    formTitleClass: "form-title",
    passwordLabel: "Password*",
    recoveryURL: "/csmcacin/recovery",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  auntieannes: {
    pageTitle: "Step 1 - Sign Into Auntie Anne's Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    signInMessage: "Sign Into for Auntie Anne’s Rewards",
    signInMessageClass: "signin-title-message",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  aamuttgg5off25: {
    pageTitle: "Step 1 - Sign Into Auntie Anne's Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  aamuttggbogo: {
    pageTitle: "Step 1 - Sign Into Auntie Anne's Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  aamuttgg5off10: {
    pageTitle: "Step 1 - Sign Into Auntie Anne's Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  aamuttgg10off30: {
    pageTitle: "Step 1 - Sign Into Auntie Anne's Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  aatmobile: {
    pageTitle: "Step 1 - Sign Into Auntie Anne's Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURL: "/tmobiletuesdaysoct22/recovery",
    recoveryURLText: "Forgot Password?",
    formTitle: "SIGN INTO AUNTIE ANNE’S REWARDS",
    formTitleClass: "form-title",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  aatmoaug27: {
    pageTitle: "Step 1 - Sign Into Auntie Anne's Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    recoveryURL: "/tmobiletuesdaysaug27/recovery",
    formTitle: "SIGN INTO AUNTIE ANNE’S REWARDS",
    formTitleClass: "form-title",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  schlotzskystmobile: {
    pageTitle: "Step 1 - Sign Into Auntie Anne's Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    formTitle: "SIGN INTO SCHLOTZSKY’S REWARDS",
    formTitleClass: "form-title",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  mcalisterstmobile: {
    pageTitle: "",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    recoveryURL: "/tmobiletuesdayssept24/recovery",
    formTitle: "SIGN INTO MCALISTER'S REWARDS",
    formTitleClass: "form-title",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  cinnabontmobile: {
    pageTitle: "",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    recoveryURL: "/tmobiletuesdaysoct15/recovery",
    formTitle: "SIGN INTO CINNABON REWARDS",
    formTitleClass: "form-title",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  aamuttggenroll: {
    pageTitle: "Step 1 - Sign Into Auntie Anne's Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  auntieannesschlotzskys: {
    pageTitle: "Step 1 - Sign Into Auntie Anne's Rewards!",
    formTitle: "Sign in",
    formTitleClass: "form-title",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURL: "/csaunsch/recovery",
    recoveryURLText: "Forgot Password?",
    signInButton: "Login",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  jamba: {
    pageTitle: "Step 1 - Sign Into Jamba Rewards!",
    signInMessage: "Sign into Jamba Rewards​",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  jambatmobile: {
    pageTitle: "Step 1 - Sign Into Jamba Rewards!",
    signInMessage: "Sign into Jamba Rewards​",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  fuelja: {
    pageTitle: "Step 1 - Sign Into Jamba Rewards!",
    formTitle: "Sign into Jamba Rewards!",
    formTitleClass: "section-title left-align",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  fueljalto: {
    pageTitle: "Step 1 - Sign Into Jamba Rewards!",
    formTitle: "Sign into Jamba Rewards!",
    formTitleClass: "section-title left-align",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  jambaauntieannes: {
    formTitle: "Sign in",
    pageTitle: "Step 1 - Sign Into Jamba Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURL: "/csjamaun/recovery",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  auntieannesjamba: {
    formTitle: "Sign in",
    formTitleClass: "form-title",
    pageTitle: "Step 1 - Sign Into Jamba Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURL: "/csaunjam/recovery",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  schlotzskysauntieannes: {
    formTitle: "Sign in",
    pageTitle: "Step 1 - Sign Into Jamba Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURL: "/csschaun/recovery",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  cinnabon: {
    pageTitle: "Step 1 - Sign Into Cinnabon's Rewards!",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign me in",
    signInMessage: "Sign Into for Cinnabon Rewards",
    signInMessageClass: "signin-title-message",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  carvel: {
    pageTitle: "Step 1 - Sign In Carvel Rewards!",
  },
  sweepsmca: {
    isFullColumn: true,
    formTitle: "Login to McAlister's Rewards",
    formDescription: "",
    pageTitle: "",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    termsPrefix: "I agree to the ",
    termsLinkText: "McAlister's Deli Terms and Conditions*",
    termsLinkURL: "https://www.mcalistersdeli.com/rewards/terms",
    recoveryURLText: "",
    signInButton: "Login",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      }
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  sweepsjamba: {
    isFullColumn: true,
    formTitle: "Sign in",
    formDescription: "",
    pageTitle: "",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    termsPrefix: "I agree to the ",
    termsLinkText: "",
    termsLinkURL: "",
    recoveryURL: "/concert/recovery",
    recoveryURLText: "Forgot Password?",
    signInButton: "Sign in",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      }
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
  sweepsmoes: {
    isFullColumn: true,
    signupInfo: "To enter, sign up or in to your Moe Rewards account below to register, and make a drink purchase using your Moe Rewards account to enter. Each purchase of a drink during the promotion window will earn you an additional entry into the sweepstakes. See official rules for no purchase method of entry.*",
    formTitle: "Log in to Moe Rewards!",
    formDescription: "Not a Moe Rewards member?",
    signUpLink: {
      href: "/soundsofsummer/rewards-signup",
      text: "Sign up here",
    },
    pageTitle: "",
    emailLabel: "Email Address*",
    passwordLabel: "Password*",
    recoveryURLText: "",
    signInButton: "Log in",
    termsPrefix: "I agree to the ",
    termsLinkText: "McAlister's Deli Terms and Conditions*",
    termsLinkURL: "https://www.mcalistersdeli.com/rewards/terms",
    termsHTML: "I agree to Moe's Terms and conditions to the following: I agree to Moe's <a href='https://www.moes.com/legal' target='_blank'>Terms and conditions</a>, the <a href='https://www.moes.com/legal#rewards-program' target='_blank'>Moe Rewards Terms</a>, and the use of my personal information as explained in the <a href='https://www.moes.com/privacy' target='_blank'>Privacy Policy</a>. If I am a CA resident, I consent to the <a href='https://www.moes.com/privacy#Financial' target='_blank'>Financial Incentive</a> associated with this loyalty program.",
    formErrorMessages: {
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    apiErrorMessages: {
      invalidCredentials:
        "Sorry, we can't authenticate you with the provided credentials.",
    },
  },
};
