import ChannelsAaFoter from "../../data/Footer/ChannelsAaFoter.data"
import { SocialURLData } from "../../data/Social.data";

export default function ChannelsAaFooter() {
  const socialData = SocialURLData.auntieannes;

  return (
    <>
      <footer className="main-footer">
        <div className="nav-wrapper">
          <div className="container">
            <img
              src={ChannelsAaFoter.logo.path}
              alt={ChannelsAaFoter.logo.alt}
              className="logo"
            />
            <div className="site-tools">
              <nav className="auxiliary-navigation">
                <ul>
                  {ChannelsAaFoter.tools.map(link => {
                    return (
                      <li className="nav-item">
                        <a
                          href={link.href}
                          className={[
                            "nav-link",
                            link.thirdParty ? "fcs-third-party" : ""
                          ].join(" ")}
                          target="_blank"
                        >
                          {link.text}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="nav-wrapper secondary">
          <div className="container">
            <p>{ChannelsAaFoter.copyrightText}</p>
            <div className="site-tools">
              <nav className="auxiliary-navigation">
                <ul>
                  {ChannelsAaFoter.copyright.map(link => {
                    return (
                      <li className="nav-item">
                        <a
                          href={link.href}
                          className={`nav-link ${link.customClass ? link.customClass : ""}`}
                          target="_blank"
                          id={link.id}
                        >

                          {link.text}
                          {link.hasTruyoPrivacyOptions && (
                            <span className="privacy-icon">
                              <img src="/common/privacy-icon.svg" />
                            </span>
                          )}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </footer>
      <section class="ada-disclaimer">
        <div class="container">
          <p>
            <span class="fcs-third-party"></span>
            <span class="sr-only">external</span>
            {ChannelsAaFoter.adaDisclaimer}
          </p>
        </div>
      </section>
    </>
  )
}