
import { Brand } from "../constants/constants";

// example local: http://sweeps.mcalistersdeli.localhost:3006
// example prod: http://sweeps.mcalistersdeli.com/picnic 
const MINIMUM_SEGMENTS_FOR_SUBDOMAIN = 2;

export function getSubdomain() {
  let subdomain = "";
  const hostname = window.location.hostname;
  const hostnameSegments = hostname.split(".");

  if (hostnameSegments.length > MINIMUM_SEGMENTS_FOR_SUBDOMAIN) {
    subdomain = hostnameSegments[0];
  }

  return subdomain;
}

// sweeps
export function isSweepsSubdomain() {
  return getSubdomain().includes("sweeps");
}

export function isSweepsMca() {
  return isSweepsSubdomain() && Brand === "mcalisters";
}

export function isSweepsJamba() {
  return isSweepsSubdomain() && Brand === "jamba";
}

export function isSweepsMoes() {
  return isSweepsSubdomain() && Brand === "moes";
}

// channels
export function isChannelsSubdomain() {
  return getSubdomain().includes("channels");
}

export function isChannelAa() {
  return isChannelsSubdomain() && Brand === "auntieannes";
}

export function isChannelCi() {
  return isChannelsSubdomain() && Brand === "cinnabon";
}

// has subdomain
export function hasPartnerSubdomain() {
  return isChannelsSubdomain() || isSweepsSubdomain();
}