import React from "react";
import { Link } from "react-router-dom";
import BrandContext from "../common/contexts/brand";
import { Interpolate } from "../common/helpers/Interpolate";
import { LinkingData } from "../data/Linking.data";
import Joi from 'joi';
import { getUser, saveUser } from "../common/user";
import { updatePartner } from "../api/updatePartner";
import { SUCCESS_URL } from "../common/constants/urls";
import SignIn from "./SignIn";
import { processApiError } from "../common/helpers/processApiError";
import { processJoiError } from "../common/helpers/processJoiError";
import { Brand } from "../common/constants/constants";

const Data = LinkingData[Brand]

class Linking extends React.Component {
  formSchema = Joi.object({
    partnerProgram: Joi.string().required().messages({
      'string.empty': Data.formErrorMessages.partnerProgram.required,
    }),
    memberId: Joi.string().min(10).required().messages({
      'string.empty': Data.formErrorMessages.memberId.required,
      'string.min': Data.formErrorMessages.memberId.invalid,
    }),
  });

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    let user = getUser();
    this.state = {
      attemptedSubmit: false,
      user,
      formData: {
        partnerProgram: Data.partnerOptions[0].value,
        memberId: "",
      },
      generalErrors: [],
      formErrors: {},
    };
  }

  handleValueChange = (e) => {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [e.target.name]: e.target.value,
        },
      }
    });
  };

  updateFormError(field, value) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      }
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = this.validateForm();
    this.setState({ attemptedSubmit: true });

    if (isValid) {
      try {
        const { token } = getUser();
        const user = await updatePartner(token, this.state.formData.partnerProgram, this.state.formData.memberId);

        saveUser(user);
        this.props.history.replace(SUCCESS_URL);
      } catch (err) {
        const result = processApiError(err, Data.apiFieldErrorMessages);
        this.setState({
          generalErrors: result.generalMessages,
          formErrors: result.fieldMessages
        });
      }
    }
  };

  validateFormField = field => {
    const subSchema = this.formSchema.extract(field);
    const result = subSchema.validate(this.state.formData[field], { abortEarly: false });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, "");
    }
  }

  validateForm = () => {
    // reset form errors
    this.setState({
      generalErrors: [],
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
     if (result.error) {
      const formErrors = processJoiError(result.error);

      const formElements = Array.from(this.formRef.current.querySelectorAll('input'));
      let ariaTargets = formElements.map(el => ({
        field: el.name,
        target: el,
      }));

      // manually add recaptcha target since it's managed by a library

      for (const obj of ariaTargets) {
        if (formErrors[obj.field]) {
          obj.target.focus();
          break;
        }
      }

      this.setState({ formErrors });
      return false;
    }
    return true;
  };

  onLoginSuccess = (user) => {
    this.setState({ user });
  };

  render() {
    const user = this.state.user;
    if (!user) {
      return <SignIn
        match={this.props.match}
        location={this.props.location}
        history={this.props.history}
        onLoginSuccess={this.onLoginSuccess}
      />;
    }

    return (
      <main className="main-content">
        <section className="container">
          <div className="row mb-medium">
            <div className="col">
              <h1>{Interpolate(Data.pageTitle, `${user.firstName}`)}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <form
                ref={this.formRef}
                onSubmit={this.handleSubmit}
                className={[
                  "form-wrapper",
                  this.state.attemptedSubmit === true ? "show-errors" : "",
                ].join(" ")}
                id="memberForm"
              >
                <div className="row">
                <div className="col">
                  <div className="error-wrapper">
                    {this.state.generalErrors.map((message, index) => {
                      return <span key={index} className="error">{message}</span>
                    })}
                  </div>
                </div>
              </div>
                <div className="row">
                <div className="col">
                    <div className="form-group">
                    <label htmlFor="partnerProgram">{Data.partnerProgram}</label>
                    <br />
                      <select
                        name="partnerProgram"
                        id="partnerProgram"
                        aria-required="true"
                        aria-invalid={Boolean(this.state.formErrors.partnerProgram)}
                        aria-describedby="partnerProgramError"
                        onChange={this.handleValueChange}
                        onBlur={e => this.validateFormField(e.target.name)}
                        value={this.state.formData.partnerProgram}
                      >
                      <option value={Data.partnerOptions[0].value}>{Data.partnerOptions[0].optionText}</option>
                      </select>
                      <span id="partnerProgramError" className="error-label">
                        {this.state.formErrors.partnerProgram}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                    <label htmlFor="memberId">{Data.memberIDLabel}</label>
                    <br />
                      <input
                        type="text"
                        id="memberId"
                        name="memberId"
                        className={[
                          this.state.formErrors.memberId ? 'invalid' : ''
                        ]}
                        aria-required="true"
                        aria-invalid={Boolean(this.state.formErrors.memberId)}
                        aria-describedby="memberIdError"
                        onChange={this.handleValueChange}
                        onBlur={e => this.validateFormField(e.target.name)}
                        value={this.state.formData.memberId}
                      />
                      <span id="memberIdError" className="error-label">
                        {this.state.formErrors.memberId}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p className="note-wrapper">
                      {Data.linkAccountInfo}
                    </p>
                  </div>
                </div>
                <div className="row v-align-end">
                  <div className="col">
                    <div className="button-wrapper">
                      <button className="primary-button">
                        {Data.linkAccountButton}
                      </button>
                    </div>
                  </div>
                </div>
                {Data.finishSubtitle && 
                <div className="row">
                  <div className="col">
                    <div className="button-wrapper">
                      <span>{Data.finishSubtitle}</span>
                      <br />
                      <Link to="/"
                      className="secondary-button"
                      type="button"
                      >
                        {Data.finishButton}
                      </Link>
                    </div>
                  </div>
                </div>
                }
              </form>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

Linking.contextType = BrandContext;

export default Linking;
