import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import BrandContext from "../../common/contexts/brand";
import { Brand } from "../../common/constants/constants";
import Hero from "../../components/Hero";
import { isSweepsMoes } from "../../common/helpers/handleSubdomains";
import { LandingData } from "../../data/Landing.data";
import Card from "../../components/Card";
import SignInForm from "../../components/SignInForm";

class SweepsSignin extends React.Component {
  handleSuccess = () => {
		this.props.history.replace('/soundsofsummer/sweeps-entry');
	}

  render() {
    let brand = Brand;

    if (isSweepsMoes()) {
      brand = "sweepsmoes"
    }

    const Data = LandingData[brand];

    return (
      <main className="main-content">
        <Hero
          backgroundImage={Data.heroBackgroundImage}
          heroTitleHTML={Data.heroTitleHTML}
          brandingLogoMobile={Data.heroBrandingLogo}
          brandingLogoAltMobile={Data.heroBrandingLogoAlt}
          heroDescription={Data.heroDescription}
          signUpSubtitle={Data.heroSignUpSubtitle}
          signUpUrl={Data.heroSignUpUrl}
          signUpButton={Data.heroSignUpButton}
          linkAccountSubtitle={Data.heroLinkAccountSubtitle}
          linkAccountButton={Data.heroLinkAccountButton}
          linkAccountButtonURL={Data.linkAccountButtonURL}
          brandingLogoDesktop={Data.heroBrandingLogo}
          heroBrandingLogoAltDesktop={Data.heroBrandingLogoAlt}
          showBgOnMobile={Data.showBgOnMobile}
        />
        {Data.showBenefitsSection && (
          <div className="primary-bg">
            <div className="container">
              <div className="row">
                <div className="col">
                  <br />
                  <br />
                  <p className="faint-text">
                    {Data.rewardsDisclaimer} 
                  </p>
                </div>
              </div>
              {Data.benefitsTitle && (
                <h2 className="section-title mt-medium">{Data.benefitsTitle}</h2>
              )}
              <div className="row three-col-wrapper center">
                {Data.benefits.map((benefit, index) => {
                  return (
                    <div
                      className="col"
                      key={index}
                    >
                      <Card
                        imageContentUrl={benefit.imageContentURL}
                        imageContentAlt={benefit.imageContentAlt}
                        title={benefit.title}
                        description={benefit.description}
                        note={benefit.note}
                      />
                    </div>
                  );
                })}
              </div>
          </div>
          </div>
        )}
        <div 
          id="login"
          className="container"
        >
          <div className="row">
            <div className="col">
              <SignInForm onLoginSuccess={this.handleSuccess}/>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col">
              <br />
              <br />
              <br />
              <br />
              <p className="faint-text">
                {Data.rewardsDisclaimer} 
              </p>
            </div>
          </div>
        </div> */}
      </main>
    )
  }
}

export default withRouter(SweepsSignin);

SweepsSignin.contextType = BrandContext;
