import React from "react";
import ContentContext from "../common/contexts/content";
import { clearUser } from "../common/user";

class AAOfferExpired extends React.Component {
  componentDidMount() {
    clearUser();
  }

  render() {
    return (
      <main className="main-content">
        <section className="mt-large">
          <div className="container">
            <h2 className="section-title">We’re sorry!​</h2>
            <p class="info-wrapper text-center">
              <strong>The offer you are trying to access has expired. To see other great Auntie Anne’s offers, follow these steps:</strong>
            </p>
            <br />
            <ol class="numbered-list text-center">
              <li>Download or open the app in the Apple Store or Google Play using buttons below.
                <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store" /></a><a href="https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play" /></a></div>
              </li>
              <li>Sign into your Auntie Anne’s Rewards account using your email and password.​</li>
              <br />
              <li>Once signed into your Auntie Anne’s Rewards account, tap “rewards”.​</li>
            </ol>
          </div>
        </section>
      </main>
    );
  }
}

AAOfferExpired.contextType = ContentContext;

export default AAOfferExpired;
