import React from "react";
import { Link, withRouter } from "react-router-dom";
import ContentContext from "../common/contexts/content";
import Joi from 'joi';
import { Recaptcha } from "../components/Recaptcha";
import { register } from "../api";
import { saveUser } from "../common/user";
import { LINK_ACCOUNT_URL } from "../common/constants/urls";
import { processApiError } from "../common/helpers/processApiError";
import { processJoiError } from "../common/helpers/processJoiError";
import { isSweepsMca } from "../common/helpers/handleSubdomains";
import { isFetchSl } from "../common/helpers/isFetchSl";
import { isFetchMca } from "../common/helpers/isFetchMca";
import {isAarpAa} from '../common/helpers/isAarpAa';
import { isAarpCi } from "../common/helpers/isAarpCi";

const recaptchaAction = 'REGISTER';

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.captchaRef = React.createRef();
    this.state = {
      attemptedSubmit: false,
      isSubmitting: false,
      formData: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        password: "",
        confirmPassword: "",
        month: "",
        day: "",
        communicationCheckbox: false,
        TOSCheckbox: false,
        recaptcha: "",
      },
      generalErrors: [],
      formErrors: {},
    };
  }

  getData() {
    return this.context.signUpData;
  }

  componentDidMount() {
    const Data = this.getData();

    this.formSchema = Joi.object({
      firstName: Joi.string().alphanum().required().messages({
        'string.empty': Data.formErrorMessages.firstName.required,
        'string.alphanum': Data.formErrorMessages.firstName.alphanum,
      }),
      lastName: Joi.string().alphanum().required().messages({
        'string.empty': Data.formErrorMessages.lastName.required,
        'string.alphanum': Data.formErrorMessages.lastName.alphanum
      }),
      email: Joi.string().required().email({ minDomainSegments: 2, tlds: false }).messages({
        'string.empty': Data.formErrorMessages.email.required,
        'string.email': Data.formErrorMessages.email.invalid,
      }),
      password: Joi.string().min(8).pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/,
        'password').required().messages({
          'string.empty': Data.formErrorMessages.password.required,
          'string.min': Data.formErrorMessages.password.condition,
          'string.pattern.name': Data.formErrorMessages.password.criteria,
        }),
      confirmPassword: Joi.string().required().valid(Joi.ref('password')).messages({
        'string.empty': Data.formErrorMessages.confirmPassword.required,
        'any.only': Data.formErrorMessages.confirmPassword.match,
      }),
      phone: Joi.string().min(10).pattern(/^[0-9]+$/).required().messages({
        'string.empty': Data.formErrorMessages.phone.required,
        'string.min': Data.formErrorMessages.phone.minLength,
        'string.pattern.base': Data.formErrorMessages.phone.invalid,
      }),
      month: Joi.string().required().messages({
        'string.empty': Data.formErrorMessages.month.required,
      }),
      day: Joi.string().required().messages({
        'string.empty': Data.formErrorMessages.day.required,
      }),
      communicationCheckbox: Joi.boolean(),
      TOSCheckbox: Joi.boolean().valid(true).messages({
        'any.only': Data.formErrorMessages.checkbox.required,
      }),
      recaptcha: Joi.string().required().messages({
        'string.empty': Data.formErrorMessages.recaptcha.required,
      }),
    });
  }

  updateFormField(field, value) {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [field]: value,
        },
      }
    });
  }

  updateFormError(field, value) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      }
    });
  }

  handleValueChange = (e) => {
    this.updateFormField(e.target.name, e.target.value);
  };

  handleCheckedChange = (e) => {
    this.updateFormField(e.target.name, e.target.checked);
  };

  handleRecaptchaSuccess = (token) => {
    const field = 'recaptcha';
    this.updateFormField(field, token);
    this.validateFormField(field)
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const Data = this.getData();
    const isValid = this.validateForm();
    this.setState({ attemptedSubmit: true, isSubmitting: true });

    if (isValid) {
      try {
        const user = await register(this.state.formData, this.state.formData.recaptcha, recaptchaAction);
        saveUser(user);

        // use custom success handler if available; otherwise default to
        // redirecting to link account
        if (this.props.onSuccess) {
          this.props.onSuccess();
        } else {
          this.props.history.replace(LINK_ACCOUNT_URL);
        }

        this.setState({ isSubmitting: false });
      } catch (err) {
        const result = processApiError(err, Data.apiFieldErrorMessages);
        this.setState({
          generalErrors: result.generalMessages,
          formErrors: result.fieldMessages,
          isSubmitting: false,
        });

        // Reset the captcha only in case of error from the backend since on success
        // the page with be changed.
        this.captchaRef.current.reset();
        this.updateFormField('recaptcha', '');
      }
    } else {
      this.setState({ isSubmitting: false });
    }
  };

  validateFormField = (field) => {
    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    let errorMessage = "";

    if (result.error) {
      for (const error of result.error.details) {
        const key = error.path[0];

        // ignore errors for other fields; this approach is chosen over extracting a sub-schema
        // because we use a joi ref for the confirm password
        if (key === field) {
          errorMessage = error.message;
        }
      }
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    // reset form errors

    this.setState({
      generalErrors: [],
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    if (result.error) {
      const formErrors = processJoiError(result.error);

      const formElements = Array.from(this.formRef.current.querySelectorAll('input, select'));
      let ariaTargets = formElements.map(el => ({
        field: el.name,
        target: el,
      }));

      // manually add recaptcha target since it's managed by a library
      ariaTargets = [...ariaTargets, {
        field: 'recaptcha',
        target: this.formRef.current.querySelector('.captcha-wrapper iframe'),
      }];

      for (const obj of ariaTargets) {
        if (formErrors[obj.field]) {
          obj.target.focus();
          break;
        }
      }
      this.setState({ formErrors });
      return false;
    }
    return true;
  };

  render() {
    const Data = this.getData();

    return (
      <>
        {Data.formTitle && (
          <h3 className={Data.formTitleClass}>{Data.formTitle}</h3>
        )}
        {Data.formDescription && (
          <p className="info-wrapper">{Data.formDescription}</p>
        )}
        <form
          ref={this.formRef}
          onSubmit={this.handleSubmit}
          className={[
            "form-wrapper",
            this.state.attemptedSubmit === true ? "show-errors" : "",
          ].join(" ")}
          id="registerForm"
        >
          {Data.SignInLinkText && (
            <div className="row">
              <div className="col">
                <p className="info-wrapper">
                  {Data.CTASignIn}{" "}
                  <Link to={`${LINK_ACCOUNT_URL}`}>
                    {Data.SignInLinkText}
                  </Link>
                </p>
              </div>
            </div>
          )}

          {Data.signUpMessage && (
            <h3 className={Data.signUpMessageClass}>{Data.signUpMessage}</h3>
          )}

          <div className="row">
            <div className="col">
              <div className="error-wrapper">
                {this.state.generalErrors.map((message, index) => {
                  return <span key={index} className="error">{message}</span>
                })}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="firstName">{Data.firstNameLabel}</label>
                <br />
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.firstName)}
                  aria-describedby="firstNameError"
                  className={[
                    this.state.formErrors.firstName ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="firstNameError" className="error-label">
                  {this.state.formErrors.firstName}
                </span>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="lastName">{Data.lastNameLabel}</label>
                <br />
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.lastName)}
                  aria-describedby="lastNameError"
                  className={[
                    this.state.formErrors.lastName ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="lastNameError" className="error-label">
                  {this.state.formErrors.lastName}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="email">{Data.emailLabel}</label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.email)}
                  aria-describedby="emailError"
                  className={[
                    this.state.formErrors.email ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="emailError" className="error-label">
                  {this.state.formErrors.email}
                </span>
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="password">{Data.passwordLabel}</label>
                <br />
                <input
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.password)}
                  aria-describedby="passwordError"
                  className={[
                    this.state.formErrors.password ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="passwordError" className="error-label">
                  {this.state.formErrors.password}
                </span>
                <span>{Data.passwordInfo}</span>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="confirmPassword">{Data.confirmPasswordLabel}</label>
                <br />
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.confirmPassword)}
                  aria-describedby="confirmPasswordError"
                  className={[
                    this.state.formErrors.confirmPassword ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="confirmPasswordError" className="error-label">
                  {this.state.formErrors.confirmPassword}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="phone">{Data.phoneLabel}</label>
                <br />
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.phone)}
                  aria-describedby="phoneError"
                  className={[
                    this.state.formErrors.phone ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="phoneError" className="error-label">
                  {this.state.formErrors.phone}
                </span>
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="month">{Data.birthdayLabel}</label>
                <div className="row">
                  <div className="col">
                    <label className="sr-only" htmlFor="month">
                      Month
                    </label>
                    <select
                      name="month"
                      id="month"
                      aria-required="true"
                      aria-invalid={Boolean(this.state.formErrors.month)}
                      aria-describedby="monthError"
                      className={[
                        'birthdate',
                        this.state.formErrors.month ? 'invalid' : '',
                      ].join(" ")}
                      onChange={this.handleValueChange}
                      onBlur={e => this.validateFormField(e.target.name)}
                      value={this.state.formData.month}
                    >
                      <option disabled value="">
                        {Data.monthLabel}
                      </option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <span id="monthError" className="error-label">
                      {this.state.formErrors.month}
                    </span>
                  </div>
                  <div className="col">
                    <label className="sr-only" htmlFor="day">
                      {Data.dayLabel}
                    </label>
                    <select
                      name="day"
                      id="day"
                      aria-required="true"
                      aria-invalid={Boolean(this.state.formErrors.day)}
                      aria-describedby="dayError"
                      className={[
                        'birthdate',
                        this.state.formErrors.day ? 'invalid' : '',
                      ].join(" ")}
                      onChange={this.handleValueChange}
                      onBlur={e => this.validateFormField(e.target.name)}
                      value={this.state.formData.day}
                    >
                      <option disabled value="">
                        {Data.dayLabel}
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </select>
                    <span id="dayError" className="error-label">
                      {this.state.formErrors.day}
                    </span>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <input
                  type="checkbox"
                  id="communicationCheckbox"
                  name="communicationCheckbox"
                  onChange={this.handleCheckedChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <label htmlFor="communicationCheckbox">
                  {Data.communicationCheckboxText}
                </label>
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="TOSCheckbox"
                  name="TOSCheckbox"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.TOSCheckbox)}
                  aria-describedby="TOSCheckboxError"
                  defaultChecked={this.state.TOSCheckbox}
                  onChange={this.handleCheckedChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <label htmlFor="TOSCheckbox">
                  {Data.termsHTML ? (
                    <span dangerouslySetInnerHTML={{ __html: Data.termsHTML }}></span>
                  ) : (
                    <>
                      {Data.termsPrefix}
                      {(Data.hasNewTerms) ? <>
                          <a href={Data.termsLinkURL} target="_blank">
                            {Data.termsLinkText}
                          </a>, the <a href={Data.rewardsTermsLinkURL} target="_blank">
                            {Data.rewardsTermsLinkText}
                          </a> and the use of my personal information as explained in the <a href={Data.privacyTermsLinkURL} target="_blank">
                            {Data.privacyTermsLinkText}
                          </a> and <a href={Data.coloradoLoyaltyProgramURL} target="_blank">
                            {Data.coloradoLoyaltyProgramText}.
                          </a> If I am a CA resident, I consent to the <a href={Data.financialIncentiveURL} target="_blank">
                            {Data.financialIncentiveText}
                          </a> associated with this loyalty program.
                        </> : <>
                        <a href={Data.termsLinkURL} target="_blank">
                          {Data.termsLinkText}
                        </a>
                        </>
                        }
                      {isSweepsMca() &&
                        <>
                          McAlister's Deli <a href={Data.termsLinkURL} target="_blank">
                            {Data.termsLinkText}
                          </a>, the <a href={Data.rewardsTermsLinkURL} target="_blank">
                            {Data.rewardsTermsLinkText}
                          </a> and the use of my personal information as explained in the <a href={Data.privacyTermsLinkURL} target="_blank">
                            {Data.privacyTermsLinkText}
                          </a> and <a href={Data.coloradoLoyaltyProgramURL} target="_blank">
                            {Data.coloradoLoyaltyProgramText}
                          </a>
                        </>

                      }
                      {(!Data.hasNewTerms && !isSweepsMca) &&
                      <a href={Data.termsLinkURL} target="_blank">
                        {Data.termsLinkText}
                      </a>}
                    </>
                  )}
                </label>
                <span id="TOSCheckboxError" className="error-label">
                  {this.state.formErrors.TOSCheckbox}
                </span>
              </div>
            </div>
          </div>
          <div className="row v-align-center mt-small">
            <div className="col">
              <div className="captcha-wrapper">
                <Recaptcha action={recaptchaAction} onSuccess={this.handleRecaptchaSuccess} ref={this.captchaRef} />
              </div>
              <span className="error-label">
                {this.state.formErrors.recaptcha}
              </span>
            </div>
            <div className="col">
              <div className={[
                "proceed-wrapper",
                Data.joinSubtitle ? "" : "h-align-end"
              ].join(" ")}>
                <button disabled={this.state.isSubmitting} className="primary-button">
                  {Data.joinButton}
                </button>
                {Data.joinSubtitle && (
                  <span>
                    {Data.joinSubtitle} <br />{Data.joinSubtitleSecond}
                  </span>
                )}
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

SignUpForm.contextType = ContentContext;

export default withRouter(SignUpForm);
