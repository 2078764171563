export const ResetLandingData = {
    moes: {
        pageTitle: "Check Your email",
        infoText: "You will receive an email with instructions about how to reset your password in a few minutes.",
    },
    schlotzskys: {
        pageTitle: "Check Your email",
        infoText: "You will receive an email with instructions about how to reset your password in a few minutes.",
    },
    mcalisters: {
        pageTitle: "Check Your email",
        infoText: "You will receive an email with instructions about how to reset your password in a few minutes.",
    },
    auntieannes: {
        pageTitle: "Check Your email",
        infoText: "You will receive an email with instructions about how to reset your password in a few minutes.",
    },
    jamba: {
        pageTitle: "Check Your email",
        infoText: "You will receive an email with instructions about how to reset your password in a few minutes.",
    },
    sweepsjamba: {
        pageTitle: "Check Your email",
        infoText: "You will receive an email with instructions about how to reset your password in a few minutes.",
    },
    cinnabon: {
        pageTitle: "Check Your email",
        infoText: "You will receive an email with instructions about how to reset your password in a few minutes.",
    },
    carvel: {
        pageTitle: "Check Your email",
        infoText: "You will receive an email with instructions about how to reset your password in a few minutes.",
    },
}