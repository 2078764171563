import React from "react";
import { Brand } from "../common/constants/constants";
import ContentContext from "../common/contexts/content";
import { ResetLandingData } from "../data/ResetLanding.data";

const Data = ResetLandingData[Brand]

class ResetLanding extends React.Component {
  render() {
    return (
      <main className="main-content">
        <section className="container">
          <div className="row">
            <div className="col">
              <h1>{Data.pageTitle}</h1>
              <p>{Data.infoText}</p>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

ResetLanding.contextType = ContentContext;

export default ResetLanding;
