import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import BrandContext from "../../common/contexts/brand";
import { Brand } from "../../common/constants/constants";
import Hero from "../../components/Hero";
import { isSweepsMoes } from "../../common/helpers/handleSubdomains";
import { LandingData } from "../../data/Landing.data";
import SweepsSignUpForm from "../../components/Forms/SweepsSignUpForm";

class SweepsSignup extends React.Component {
  handleSuccess = () => {
		this.props.history.replace('/soundsofsummer/sweeps-entry');
	}

  render() {
    let brand = Brand;

    if (isSweepsMoes()) {
      brand = "sweepsmoes"
    }

    const Data = LandingData[brand];

    return (
      <main className="main-content">
        <Hero
          backgroundImage={Data.heroBackgroundImage}
          showBgOnMobile={Data.showBgOnMobile}
        />
        <div className="container">
          <div className="row">
            <div className="col">
              <SweepsSignUpForm onSuccess={this.handleSuccess}/>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <br />
              <br />
              <br />
              <br />
              <p className="faint-text">
                {Data.rewardsDisclaimer} 
              </p>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default withRouter(SweepsSignup);

SweepsSignup.contextType = BrandContext;
