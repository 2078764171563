import React from "react";
import BrandContext from "../../common/contexts/brand";
import { Brand, PARTNERS } from "../../common/constants/constants";
import { isChannelAa, isChannelCi } from "../../common/helpers/handleSubdomains";
import { LandingData } from "../../data/Landing.data";
import Hero from "../../components/Hero";
import SignUpForm from "../../components/SignUpForm";
import { updatePartner } from "../../api/updatePartner";
import { getUser, saveUser } from "../../common/user";

export default class ChannelsLanding extends React.Component {
  handleSuccess = async () => {
		const { token } = getUser();
		let user = await updatePartner(token, PARTNERS.Partner4, undefined, undefined);
		saveUser(user);

		// redirect to success
		this.props.history.replace('/success');
	}

  render() {
    let brand = Brand;

    if (isChannelAa()) {
      brand = "channelsaa";
    } else if(isChannelCi()) {
      brand = "channelsci";
    }

    const Data = LandingData[brand];

    return (
      <>
        <main className="main-content">
          <Hero
            backgroundImage={Data.heroBackgroundImage}
            heroTitle={Data.heroTitle}
            showBgOnMobile={Data.showBgOnMobile}
          />
          <section className="container">
            <div className="row">
              <div className="col">
                <h1>{Data.pageTitle}</h1>
                <p className="info-wrapper">
                  {Data.infoText}
                </p>
              </div>
            </div>
            <SignUpForm onSuccess={this.handleSuccess} />
          </section>
        </main>
      </>
    )
  }
}

ChannelsLanding.contextType = BrandContext;