import React from 'react'

function Disclaimer(props) {
    let elements = [];

    if (typeof props.disclaimerText === 'string') {
        elements.push(props.disclaimerText);
    } else if (Array.isArray(props.disclaimerText)) {
        // handle each type of element
        for (const iterator of props.disclaimerText) {
            if (iterator.link) {
                elements.push(<a href={iterator.link}>{iterator.text}</a>);
            } else if (iterator.text) {
                elements.push(iterator.text);
            }
        }
    } else {
        console.error(`Unexpected type '${typeof props.disclaimerText}' for 'disclaimerText'`);
    }

    return (
        <div className="disclaimer-component">
            <p dangerouslySetInnerHTML={{ __html: elements }}></p>
        </div>
    )
}

export default Disclaimer;
