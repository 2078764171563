export const RecoveryData = {
    moes: {
        pageTitle: "Reset your password for Moe Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    moestmobile: {
        pageTitle: "Reset your password for Moe Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    schlotzskys: {
        pageTitle: "Reset password your for Schlotzsky's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    schlotzskysauntieannes: {
        pageTitle: "Reset password your for Schlotzsky's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    mcalisters: {
        pageTitle: "Reset password your for McAlister's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    auntieannes: {
        pageTitle: "Reset password your for Auntie Anne's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    aatmoaug27: {
        pageTitle: "Reset password your for Auntie Anne's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        resetLandingURL: "/tmobiletuesdaysaug27/reset-landing",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    aatmobile: {
        pageTitle: "Reset password for your Auntie Anne's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        resetLandingURL: "/tmobiletuesdaysoct22/reset-landing",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    mcalisterstmobile: {
        pageTitle: "Reset password your for McAlister's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        resetLandingURL: "/tmobiletuesdayssept24/reset-landing",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    jamba: {
        pageTitle: "Reset password your for Jamba Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    fuelja: {
        pageTitle: "Reset password your for Jamba Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    fueljalto: {
        pageTitle: "Reset password your for Jamba Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    jambaauntieannes: {
        pageTitle: "Reset password your for Jamba Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    sweepsjamba: {
        pageTitle: "Reset password your for Jamba Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    cinnabon: {
        pageTitle: "Reset password your for Cinnabon Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    cinnabontmobile: {
        pageTitle: "Reset password for your Cinnabon Rewards account",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    mcalisterscinnabon: {
        pageTitle: "Reset password your for McAlister's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    cinnabonmcalisters: {
        pageTitle: "Reset password your for Cinnabon Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    cinnabonmoes: {
        pageTitle: "Reset password your for Cinnabon Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    auntieannesschlotzskys: {
        pageTitle: "Reset password your for Auntie Anne's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    auntieannesjamba: {
        pageTitle: "Reset password your for Auntie Anne's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    moescinnabon: {
        pageTitle: "Reset password your for Moe's Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
    carvel: {
        pageTitle: "Reset password your for Carvel Rewards",
        emailLabel: "Email",
        resetButton: "Reset Password",
        formErrorMessages: {
            email: {
                required: "Email is required",
                invalid: "Enter a valid email adress",
            },
            recaptcha: {
                required: "reCAPTCHA is required",
            },
        },
    },
}