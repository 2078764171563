export const SignUpData = {
  moes: {
    pageTitle: "Step 1 - Join Moe Rewards!",
    CTASignIn: "",
    SignInLinkText: "",
    signUpMessage: "Sign Up for Moe Rewards​",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "Terms and Conditions*",
    termsLinkURL: "https://www.moes.com/legal",
    rewardsTermsLinkURL: "https://www.moes.com/legal#rewards-program",
    rewardsTermsLinkText: "Moe Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.moes.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.moes.com/privacy#COloyalty",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.moes.com/privacy#FinancialIncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  moestmobile: {
    pageTitle: "Step 1 - Join Moe Rewards!",
    formTitle: "SIGN UP FOR MOE REWARDS",
    formTitleClass: "form-title",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.moes.com/legal" target="_blank">Moe's Southwest Grill Terms and Conditions</a>, the <a href="https://www.moes.com/legal#rewards-program" target="_blank">Moe Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.moes.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.moes.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.moes.com/privacy#Financialncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    rewardsTermsLinkText: "Moe Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.moes.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.moes.com/privacy#COloyalty",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.moes.com/privacy#FinancialIncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign me up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  schlotzskys: {
    pageTitle: "Step 1 - Join Schlotzsky's Rewards!",
    CTASignIn: "",
    SignInLinkText: "",
    signUpMessage: "Sign Up for Schlotzsky's Rewards​",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to ",
    termsLinkText: "Terms and Conditions*",
    termsLinkURL: "https://www.schlotzskys.com/legal",
    rewardsTermsLinkURL: "https://www.schlotzskys.com/legal#REWARDS",
    rewardsTermsLinkText: "Schlotzsky's Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.schlotzskys.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.schlotzskys.com/privacy#COloyalty",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.schlotzskys.com/privacy#Financialncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  mcalisters: {
    pageTitle: "Step 1 - Join McAlister's Rewards!",
    CTASignIn: "",
    SignInLinkText: "",
    signUpMessage: "Sign Up for McAlister's Rewards​",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "McAlister's Deli Terms and Conditions*",
    termsLinkURL: "https://www.mcalistersdeli.com/legal",
    rewardsTermsLinkURL: "https://www.mcalistersdeli.com/legal#mca-rewards",
    rewardsTermsLinkText: "McAlister's Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.mcalistersdeli.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.mcalistersdeli.com/privacy#COloyalty",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.mcalistersdeli.com/privacy#FinancialIncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "Step 2 - Link Your Account",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  auntieannes: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "​",
    SignInLinkText: "",
    signUpMessage: "Sign Up for Auntie Anne’s Rewards​",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "Auntie Anne's Terms and Conditions*",
    termsLinkURL: "https://www.auntieannes.com/legal",
    rewardsTermsLinkURL: "https://www.auntieannes.com/legal#rewards-terms",
    rewardsTermsLinkText: "Auntie Anne’s Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.auntieannes.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.auntieannes.com/privacy",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.auntieannes.com/privacy#Financialncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  aamuttggenroll: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.auntieannes.com/legal" target="_blank">Auntie Anne's Terms and Conditions</a>, the <a href="https://www.auntieannes.com/legal#rewards-terms" target="_blank">Auntie Anne’s Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.auntieannes.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.auntieannes.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.auntieannes.com/privacy#Financialncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  aamuttgg5off25: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.auntieannes.com/legal" target="_blank">Auntie Anne's Terms and Conditions</a>, the <a href="https://www.auntieannes.com/legal#rewards-terms" target="_blank">Auntie Anne’s Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.auntieannes.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.auntieannes.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.auntieannes.com/privacy#Financialncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  aamuttggbogo: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.auntieannes.com/legal" target="_blank">Auntie Anne's Terms and Conditions</a>, the <a href="https://www.auntieannes.com/legal#rewards-terms" target="_blank">Auntie Anne’s Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.auntieannes.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.auntieannes.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.auntieannes.com/privacy#Financialncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  aamuttgg5off10: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.auntieannes.com/legal" target="_blank">Auntie Anne's Terms and Conditions</a>, the <a href="https://www.auntieannes.com/legal#rewards-terms" target="_blank">Auntie Anne’s Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.auntieannes.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.auntieannes.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.auntieannes.com/privacy#Financialncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  aamuttgg10off30: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.auntieannes.com/legal" target="_blank">Auntie Anne's Terms and Conditions</a>, the <a href="https://www.auntieannes.com/legal#rewards-terms" target="_blank">Auntie Anne’s Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.auntieannes.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.auntieannes.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.auntieannes.com/privacy#Financialncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  aatmobile: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    SignInLinkText: "",
    formTitle: "SIGN UP FOR AUNTIE ANNE’S REWARDS",
    formTitleClass: "form-title",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.auntieannes.com/legal" target="_blank">Auntie Anne's Terms and Conditions</a>, the <a href="https://www.auntieannes.com/legal#rewards-terms" target="_blank">Auntie Anne’s Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.auntieannes.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.auntieannes.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.auntieannes.com/privacy#Financialncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  aatmoaug27: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    SignInLinkText: "",
    formTitle: "SIGN UP FOR AUNTIE ANNE’S REWARDS",
    formTitleClass: "form-title",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.auntieannes.com/legal" target="_blank">Auntie Anne's Terms and Conditions</a>, the <a href="https://www.auntieannes.com/legal#rewards-terms" target="_blank">Auntie Anne’s Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.auntieannes.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.auntieannes.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.auntieannes.com/privacy#Financialncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  schlotzskystmobile: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    SignInLinkText: "",
    formTitle: "SIGN UP FOR SCHLOTZSKY'S REWARDS",
    formTitleClass: "form-title",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.schlotzskys.com/legal" target="_blank">Schlotzsky's Terms and Conditions</a>, the <a href="https://www.schlotzskys.com/legal#REWARDS" target="_blank">Schlotzsky’s Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.schlotzskys.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.schlotzskys.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.schlotzskys.com/privacy#FinancialIncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  mcalisterstmobile: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    SignInLinkText: "",
    formTitle: "SIGN UP FOR MCALISTER'S REWARDS",
    formTitleClass: "form-title",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.mcalistersdeli.com/legal" target="_blank">McAlister's Terms and Conditions</a>, the <a href="https://www.mcalistersdeli.com/legal#mca-rewards" target="_blank">McAlister’s Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.mcalistersdeli.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.mcalistersdeli.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.mcalistersdeli.com/privacy#FinancialIncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  cinnabontmobile: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    SignInLinkText: "",
    formTitle: "SIGN UP FOR CINNABON REWARDS",
    formTitleClass: "form-title",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "",
    termsLinkText: "",
    termsLinkURL: "",
    termsHTML: `
      I agree to <a href="https://www.cinnabon.com/legal" target="_blank"> Terms and Conditions</a>, the <a href="https://www.cinnabon.com/legal#rewards-terms" target="_blank">Cinnabon Rewards Terms and Conditions</a> and the use of my personal information as explained in the <a href="https://www.cinnabon.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.cinnabon.com/privacy#COloyalty" target="_blank">Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href="https://www.cinnabon.com/privacy#Financialncentive" target="_blank">Financial Incentive</a> associated with this loyalty program.
    `,
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign Up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  auntieannesschlotzskys: {
    pageTitle: "Step 1 - Join Pretzel Perks!",
    CTASignIn: "",
    formTitle: "Sign up",
    formTitleClass: "form-title",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to ",
    termsLinkText: "Auntie Anne's Terms and Conditions*",
    termsLinkURL: "https://www.auntieannes.com/terms",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  sweepsjamba: {
    formTitle: "Enroll in Jamba Rewards!",
    pageTitle: "",
    CTASignIn: "Enroll in Jamba Rewards!",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Jamba Rewards Terms and Conditions*",
    termsHTML: "I agree to <a href='https://www.jamba.com/legal' target='_blank'>Jamba Terms and conditions</a>, the <a href='https://www.jamba.com/legal#rewards-terms' target='_blank'>My Jamba Rewards Terms and Conditions</a>, and the use of my personal information as explained in the <a href='https://www.jamba.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://www.jamba.com/privacy#ca' target='_blank'>Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href='https://www.jamba.com/privacy#Financialncentive' target='_blank'>Financial Incentive</a> associated with this loyalty program.",
    termsLinkURL: "https://www.jamba.com/terms",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  jamba: {
    pageTitle: "Step 1 - Join Jamba Rewards!",
    CTASignIn: "",
    SignInLinkText: "",
    signUpMessage: "Sign Up for Jamba Rewards​",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "Jamba Rewards Terms and Conditions*",
    termsLinkURL: "https://www.jamba.com/legal",
    rewardsTermsLinkURL: "https://www.jamba.com/legal#rewards-terms",
    rewardsTermsLinkText: "My Jamba Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.jamba.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.jamba.com/privacy#ca",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.jamba.com/privacy#Financialncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  jambatmobile: {
    pageTitle: "Step 1 - Join Jamba Rewards!",
    CTASignIn: "",
    SignInLinkText: "",
    signUpMessage: "Sign Up for Jamba Rewards​",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "Jamba Rewards Terms and Conditions*",
    termsLinkURL: "https://www.jamba.com/legal",
    rewardsTermsLinkURL: "https://www.jamba.com/legal#rewards-terms",
    rewardsTermsLinkText: "My Jamba Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.jamba.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.jamba.com/privacy#ca",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.jamba.com/privacy#Financialncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  fuelja: {
    pageTitle: "",
    formTitle: "Join Jamba Rewards!",
    formTitleClass: "section-title left-align",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Jamba Rewards Terms and Conditions*",
    termsLinkURL: "https://www.jamba.com/terms",
    termsHTML: "I agree to <a href='https://www.jamba.com/legal' target='_blank'>Jamba Terms and conditions</a>, the <a href='https://www.jamba.com/legal#rewards-terms' target='_blank'>My Jamba Rewards Terms and Conditions</a>, and the use of my personal information as explained in the <a href='https://www.jamba.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://www.jamba.com/privacy#ca' target='_blank'>Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href='https://www.jamba.com/privacy#Financialncentive' target='_blank'>Financial Incentive</a> associated with this loyalty program.",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  fueljalto: {
    pageTitle: "",
    formTitle: "Join Jamba Rewards!",
    formTitleClass: "section-title left-align",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Jamba Rewards Terms and Conditions*",
    termsLinkURL: "https://www.jamba.com/terms",
    termsHTML: "I agree to <a href='https://www.jamba.com/legal' target='_blank'>Jamba Terms and conditions</a>, the <a href='https://www.jamba.com/legal#rewards-terms' target='_blank'>My Jamba Rewards Terms and Conditions</a>, and the use of my personal information as explained in the <a href='https://www.jamba.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://www.jamba.com/privacy#ca' target='_blank'>Colorado Loyalty Program Disclosures</a>. If I am a CA resident, I consent to the <a href='https://www.jamba.com/privacy#Financialncentive' target='_blank'>Financial Incentive</a> associated with this loyalty program.",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  cinnabon: {
    pageTitle: "Step 1 - Join Cinnabon Rewards!",
    CTASignIn: "",
    SignInLinkText: "",
    signUpMessage: "Sign Up for Cinnabon Rewards​",
    signUpMessageClass: "signup-title-message",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "Cinnabon Rewards Terms and Conditions*",
    termsLinkURL: "https://www.cinnabon.com/legal",
    rewardsTermsLinkURL: "https://www.cinnabon.com/legal#rewards-terms",
    rewardsTermsLinkText: "Cinnabon Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.cinnabon.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.cinnabon.com/privacy#COloyalty",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.cinnabon.com/privacy#Financialncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  carvel: {
    pageTitle: "Step 1 - Join Carvel Rewards!",
    CTASignIn: "Already a Carvel Rewards member?",
    SignInLinkText: "Link your account",
    termsPrefix: "I agree to the ",
    termsLinkText: "Carvel Terms and Conditions*",
    termsLinkURL: "#",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
  },
  fetchmca: {
    pageTitle: "Join McAlister's Rewards!",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "McAlister's Deli Terms and Conditions*",
    termsLinkURL: "https://www.mcalistersdeli.com/legal",
    rewardsTermsLinkURL: "https://www.mcalistersdeli.com/legal#mca-rewards",
    rewardsTermsLinkText: "McAlister’s Rewards Terms and Conditions",
    privacyTermsLinkURL: "https://www.mcalistersdeli.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.mcalistersdeli.com/privacy#COloyalty",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.mcalistersdeli.com/privacy#FinancialIncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },

  fetchaa: {
    pageTitle: "Join McAlister's Rewards!",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "Auntie Anne's Terms and Conditions*",
    termsLinkURL: "https://www.auntieannes.com/legal",
    rewardsTermsLinkURL: "https://www.auntieannes.com/legal#rewards-terms",
    rewardsTermsLinkText: "Auntie Anne’s Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.auntieannes.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.auntieannes.com/privacy",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.auntieannes.com/privacy#Financialncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  fetchsl: {
    pageTitle: "Join McAlister's Rewards!",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "Schlotzsky’s Terms and Conditions*",
    termsLinkURL: "https://www.schlotzskys.com/legal",
    rewardsTermsLinkURL: "https://www.schlotzskys.com/legal#rewards",
    rewardsTermsLinkText: "Schlotzsky’s Rewards Terms and Conditions",
    privacyTermsLinkURL: "https://www.schlotzskys.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.schlotzskys.com/privacy#coloyalty",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.schlotzskys.com/privacy#FinancialIncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  mcalisterscinnabon: {
    pageTitle: "",
    formTitle: "Join McAlister's Rewards!",
    formTitleClass: "form-title",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "McAlister's Terms and Conditions*",
    termsLinkURL: "https://www.mcalistersdeli.com/legal",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  cinnabonmcalisters: {
    pageTitle: "",
    formTitle: "Join Cinnabon Rewards!",
    formTitleClass: "form-title",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Cinnabon Terms and Conditions*",
    termsLinkURL: "https://www.cinnabon.com/legal",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  cinnabonmoes: {
    pageTitle: "",
    formTitle: "Join Cinnabon Rewards!",
    formTitleClass: "form-title",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Cinnabon Terms and Conditions*",
    termsLinkURL: "https://www.cinnabon.com/legal",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  moescinnabon: {
    pageTitle: "",
    formTitle: "Join Moe's Rewards!",
    formTitleClass: "form-title",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Moe's Terms and Conditions*",
    termsLinkURL: "https://www.moes.com/legal",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  fetchci: {
    pageTitle: "",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "Cinnabon Terms and Conditions*",
    termsLinkURL: "https://www.cinnabon.com/legal",
    rewardsTermsLinkURL: "https://www.cinnabon.com/legal#rewards-terms",
    rewardsTermsLinkText: "Cinnabon Rewards Terms and Conditions",
    privacyTermsLinkURL: "https://www.cinnabon.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.cinnabon.com/privacy#COloyalty",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.cinnabon.com/privacy#Financialncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  sweepsmca: {
    formTitle: "Join McAlister’s Rewards",
    formDescription: "Enroll in McAlister’s Rewards to enter the sweeps",
    pageTitle: "",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to ",
    termsLinkText: "Terms and Conditions",
    termsLinkURL: "https://www.mcalistersdeli.com/legal",
    rewardsTermsLinkText: "McAlister’s Rewards Terms and Conditions",
    rewardsTermsLinkURL: "https://www.mcalistersdeli.com/legal#mca-rewards",
    privacyTermsLinkText: "Privacy Policy",
    privacyTermsLinkURL: "https://www.mcalistersdeli.com/privacy-policy",
    coloradoLoyaltyProgramURL: "https://www.mcalistersdeli.com/privacy#Virginia",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosures",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  channelsaa: {
    pageTitle: "",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "Auntie Anne's Terms and Conditions*",
    termsLinkURL: "https://www.auntieannes.com/legal",
    rewardsTermsLinkURL: "https://www.auntieannes.com/legal#rewards-terms",
    rewardsTermsLinkText: "Auntie Anne’s Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.auntieannes.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.auntieannes.com/privacy",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.auntieannes.com/privacy#Financialncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  channelsci: {
    pageTitle: "",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    hasNewTerms: true,
    termsPrefix: "I agree to the ",
    termsLinkText: "Terms and Conditions*",
    termsLinkURL: "https://www.cinnabon.com/legal",
    rewardsTermsLinkURL: "https://www.cinnabon.com/legal#rewards-terms",
    rewardsTermsLinkText: "Cinnabon Rewards Terms and Conditions ",
    privacyTermsLinkURL: "https://www.cinnabon.com/privacy",
    privacyTermsLinkText: "Privacy Policy",
    coloradoLoyaltyProgramURL: "https://www.cinnabon.com/privacy#COloyalty",
    coloradoLoyaltyProgramText: "Colorado Loyalty Program Disclosure",
    financialIncentiveURL: "https://www.cinnabon.com/privacy#Financialncentive",
    financialIncentiveText: "Financial Incentive",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Sign up",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  fetchja: {
    pageTitle: "",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Jamba Rewards Terms and Conditions*",
    termsLinkURL: "https://www.jamba.com/terms",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  jambaauntieannes: {
    formTitle: "Enroll in Jamba Rewards!",
    pageTitle: "",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Jamba Rewards Terms and Conditions*",
    termsLinkURL: "https://www.jamba.com/terms",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  auntieannesjamba: {
    formTitle: "Sign up",
    formTitleClass: "form-title",
    pageTitle: "",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Auntie Anne's Rewards Terms and Conditions*",
    termsLinkURL: "https://www.auntieannes.com/legal",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  schlotzskysauntieannes: {
    formTitle: "Join Schlotzsky's Rewards!",
    pageTitle: "",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Schlotzsky's Rewards Terms and Conditions*",
    termsLinkURL: "https://www.schlotzskys.com/legal#REWARDS",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
  sweepsmoes: {
    formTitle: "Join Moe's Rewards!",
    formDescription: "Enroll in Moe’s Rewards to start your sweepstakes entry. See official rules for methods of entry*",
    pageTitle: "Step 1 - Join Moe Rewards!",
    CTASignIn: "",
    SignInLinkText: "",
    firstNameLabel: "First Name*",
    lastNameLabel: "Last Name*",
    emailLabel: "Email*",
    passwordLabel: "Password*",
    passwordInfo:
      "Minimum 8 characters, one uppercase, one lowercase letter, one number and one special character",
    confirmPasswordLabel: "Confirm Password*",
    phoneLabel: "Mobile Phone*",
    zipCodeLabel: "Zipcode*",
    birthdayLabel: "Birthday*",
    monthLabel: "Month",
    dayLabel: "Day",
    termsPrefix: "I agree to the ",
    termsLinkText: "Moe's Terms and Conditions*",
    termsLinkURL: "https://www.moes.com/legal#rewards-program",
    termsHTML: "I agree to Moe's Terms and conditions to the following: I agree to Moe's <a href='https://www.moes.com/legal' target='_blank'>Terms and conditions</a>, the <a href='https://www.moes.com/legal#rewards-program' target='_blank'>Moe Rewards Terms</a>, and the use of my personal information as explained in the <a href='https://www.moes.com/privacy' target='_blank'>Privacy Policy</a>. If I am a CA resident, I consent to the <a href='https://www.moes.com/privacy#Financial' target='_blank'>Financial Incentive</a> associated with this loyalty program.",
    communicationCheckboxText:
      "I would like to receive email marketing communications",
    joinButton: "Join Now",
    joinSubtitle: "",
    joinSubtitleSecond: "",

    formErrorMessages: {
      firstName: {
        required: "First Name is required",
        alphanum: "First Name must contain alpha-numeric characters",
      },
      lastName: {
        required: "Last Name is required",
        alphanum: "Last Name must contain alpha-numeric characters",
      },
      email: {
        required: "Email is required",
        invalid: "Enter a valid email adress",
      },
      password: {
        required: "Password is required",
        condition: "Password strength must meet security conditions",
        criteria: "Password does not match security criteria",
      },
      confirmPassword: {
        required: "Confirm Password needs to match Password",
        match: "Passwords do not match",
      },
      phone: {
        required: "Phone is required",
        minLength: "Phone needs to be at least 10 digits",
        invalid: "Enter a valid phone number",
      },
      month: {
        required: "Month is required",
      },
      day: {
        required: "Day is required",
      },
      zipCode: {
        required: "Zipcode is required",
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      email: {
        alreadyExists: "Email is already in use",
      },
      phone: {
        alreadyExists: "Mobile Phone is already in use",
      },
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
  },
};
