import { ApiError } from "./exception";

export async function recoverPassword(email, recaptchaToken, recaptchaAction) {
  const response = await fetch(`${process.env.REACT_APP_API}/api/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 
      email,
      recaptcha: {
        token: recaptchaToken,
        action: recaptchaAction,
      }
    })
  });

  if (response.ok) {
    return;
  }

  // we assume there's an error if the response is not ok
  const obj = await response.json();
  if (obj.error) {
    throw new ApiError(obj.error);
  }

  throw new Error(obj);
}