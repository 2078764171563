import React from "react";
import { withRouter } from "react-router-dom";
import { updatePartner } from "../api/updatePartner";
import { PARTNERS, CROSS_SELL_BRANDS } from "../common/constants/constants";
import ContentContext from "../common/contexts/content";
import { getUser, saveUser } from "../common/user";
import Disclaimer from "../components/Disclaimer";
import Hero from "../components/Hero";
import SignUpForm from "../components/SignUpForm";
import SignInForm from "../components/SignInForm";
import { getBrandPartner } from "../common/constants/crossSell";

class CrossSellSignUp extends React.Component {
	handleSuccess = async () => {
		const { token } = getUser();

        const partner = getBrandPartner(this.props.match.path);

		let user = await updatePartner(token, partner);
		saveUser(user);

		// redirect to success
		this.props.history.replace(`${this.props.match.path}/success`);
	}

	getData() {
		return this.context.landingData;
	}

	render() {
		const Data = this.getData();
		return <>
			<main className="main-content">
				<Hero
					backgroundImage={Data.heroBackgroundImage}
					heroTitle={Data.heroTitle}
					heroNote={Data.heroNote}
					brandingLogoMobile={Data.heroBrandingLogo}
					brandingLogoAltMobile={Data.heroBrandingLogoAlt}
					heroDescription={Data.heroDescription}
					linkAccountSubtitle={Data.heroLinkAccountSubtitle}
					linkAccountButton={Data.heroLinkAccountButton}
					brandingLogoDesktop={Data.heroBrandingLogo}
					heroBrandingLogoAltDesktop={Data.heroBrandingLogoAlt}
				/>
				<section className="container">
					<div className="row">
						<div className="col">
							<h1>{Data.pageTitle}</h1>
							<p className="info-wrapper">
								{Data.infoText}
							</p>
						</div>
					</div>
					<div className={"row sectioned"}>
						<div className="col">
							<SignUpForm onSuccess={this.handleSuccess} />
						</div>
						<div className="col">
                            <SignInForm onLoginSuccess={this.handleSuccess} />
                        </div>
					</div>
					<div className="container">
						<Disclaimer
							disclaimerText={Data.rewardsDisclaimer}
						/>
						<Disclaimer
							disclaimerText={Data.disclaimerText}
						/>
					</div>
				</section>
			</main>
		</>
	}
}

CrossSellSignUp.contextType = ContentContext;

export default withRouter(CrossSellSignUp);
