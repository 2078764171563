import React from "react";
import { Link } from "react-router-dom";

function DynamicLink(props) {
  return (
        <React.Fragment>
            {props.linkData.target === "internal" ?
                <Link
                    to={props.linkData.URL}
                >
                    {props.linkData.text}
                </Link>
            :
                <a
                    href={props.linkData.URL}
                    target={props.linkData.target}
                >
                    {props.linkData.text}
                </a>
            }
        </React.Fragment>
    );
}

export default DynamicLink;
