export const Brand = process.env.REACT_APP_BRAND;
export const DEV_MENU = process.env.REACT_APP_DEV_MENU;
export const HAS_FETCH_PARTNER = process.env.REACT_APP_HAS_FETCH_PARTNER;
export const HAS_FUEL_PARTNER = process.env.REACT_APP_HAS_FUEL_PARTNER;
export const HAS_SHOW_FORM = process.env.REACT_APP_HAS_SHOW_FORM;
export const CROSS_SELL_BRANDS = process.env.REACT_APP_CROSS_SELL_BRANDS;
export const POLL_ID = process.env.REACT_APP_POLL_ID;

export const PARTNERS = {
	Partner1: 'partner1',
	Partner2: 'partner2',
	Partner4: 'partner4',
};

export const TRUYO_CDN_URL = "https://truyoproductionuscdn.truyo.com/js";
export const TRUYO_TOKENS = {
	auntieannes: "dee8946b8d3fdd2acef3110d531d791a",
	auntieannesschlotzskys: "dee8946b8d3fdd2acef3110d531d791a",
	cinnabon: "8af1d33eafaf8f743fc945fda99e6d08",
	cinnabonmcalisters: "8af1d33eafaf8f743fc945fda99e6d08",
	cinnabonmoes: "8af1d33eafaf8f743fc945fda99e6d08",
	jamba: "30a10adde819acab245b91ff3394109c",
	jambaauntieannes: "30a10adde819acab245b91ff3394109c",
	mcalisters: "41eb70a5b76cc2b84b2eee8f6fc67c6c",
	mcalisterscinnabon: "41eb70a5b76cc2b84b2eee8f6fc67c6c",
	moes: "1729f3af51f140c75a400a48ef22bac2",
	moescinnabon: "1729f3af51f140c75a400a48ef22bac2",
}

const AA_MUT_MAP = {
	'auntieannes': {
			'/mutgg5off25': 'partner14',
			'/mutggbogo6m29p02s': 'partner15',
			'/mut5off1004a03t04s': 'partner16',
			'/mutgg10off3012m05g05s': 'partner17',
	}
};

export function getAaMutPaths() {
	return Object.keys(AA_MUT_MAP[Brand]);
}

export function getAaMutPartner(path) {
	return AA_MUT_MAP[Brand][path];
}