import React from "react";
import BrandContext from "../common/contexts/brand";
import { SignUpData } from "../data/SignUp.data";
import { Brand } from "../common/constants/constants";
import { isFetchMca } from "../common/helpers/isFetchMca";
import SignUpForm from "../components/SignUpForm";
import { isFetchSl } from "../common/helpers/isFetchSl";
import { isSweepsSubdomain } from "../common/helpers/handleSubdomains";

class SignUp extends React.Component {
  getData() {
    let brand = Brand;

    if (isFetchMca()) {
      brand = "fetchmca";
    } else if (isFetchSl()) {
      brand = "fetchsl";
    }

    return SignUpData[brand];
  }

  render() {
    const Data = this.getData();

    return (
      <>
        <main className="main-content">
          <section className="container">
            <div className="row">
              <div className="col">
                <h1>{Data.pageTitle}</h1>
              </div>
            </div>
            <SignUpForm />
          </section>
        </main>
      </>
    );
  }
}

SignUp.contextType = BrandContext;

export default SignUp;
