import React from "react";

export default class FocusOutlines extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enableOutline: false,
    };
  }
  componentDidMount() {
    window.addEventListener("keydown", this.handleKeydown);
    window.addEventListener("mousedown", this.handleMousedown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('mousedown', this.handleMousedown);
  }

  handleKeydown = (e) => {
    // Tab - 9
    // Space - 32
    // Enter - 13
    if ([9, 32, 13].includes(e.keyCode)) {
      this.setState({ enableOutline: true });
    }
  };

  handleMousedown = (e) => {
    this.setState({enableOutline: false});
  }

  render() {
    const outlineClass = this.state.enableOutline ? 'focus-outlines' : '';

    return <div className={`${this.props.className} ${outlineClass}`}>
      {this.props.children}
    </div>
  }
}