import { Brand } from "./constants";

const map = {
    'auntieannes': {
        '/csaunsch': 'partner9',
        '/csaunjam': 'partner10',
    },
    'cinnabon': {
        '/cscinmca': 'partner9',
        '/cscinmoe': 'partner10',
    },
    'jamba': {
        '/csjamaun': 'partner9',
    },
    'mcalisters': {
        '/csmcacin': 'partner9',
    },
   'moes': {
        '/csmoecin': 'partner9',
    },
    'schlotzskys': {
        '/csschaun': 'partner9',
    },
};

export function getBrandPaths() {
    return Object.keys(map[Brand]);
}

export function getBrandPartner(path) {
    return map[Brand][path];
}