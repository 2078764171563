import React from "react";
import { Link, withRouter } from "react-router-dom";
import ContentContext from "../common/contexts/content";

class Header extends React.Component {
  render() {
    const HeaderContentData = this.context.headerData;

    return (
      <header className="main-header">
        <div className="container header-content">
          <div className="logo-wrapper">
            <Link to={HeaderContentData.logoUrl || `/`}>
              <img
                className="logo"
                src={HeaderContentData.logoPath}
                alt={HeaderContentData.logoAlt}
              />
            </Link>
          </div>
          {HeaderContentData.benefitsLogo && (
            <div className="benefits-wrapper">
                <img
              className="benefits-logo"
              src={HeaderContentData.benefitsLogo}
              alt={HeaderContentData.benefitsAlt}
              />
            </div>)}
        </div>
      </header>
    );
  }
}

Header.contextType = ContentContext;

export default withRouter(Header);
