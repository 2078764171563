import React from "react";
import { withRouter } from "react-router-dom";
import { Brand } from "../common/constants/constants";
import BrandContext from "../common/contexts/brand";
import { isSweepsJamba, isSweepsMca } from "../common/helpers/handleSubdomains";
import Hero from "../components/Hero";
import SignInForm from "../components/SignInForm";
import SignUpForm from "../components/SignUpForm";
import { LandingData } from "../data/Landing.data";

class SweepsLanding extends React.Component {
	constructor(props) {
		super(props);
	}

	handleSuccess = () => {
		this.props.history.replace(this.props.successPath);
	}

	getData() {
		let brand = Brand;

		if (isSweepsMca()) {
			brand = "sweepsmca";
		}

		if (isSweepsJamba()) {
			brand = "sweepsjamba";
		}

		return LandingData[brand];
	}

	render() {
		const Data = this.getData();
		return <>
			<main className="main-content">
				<Hero
					backgroundImage={Data.heroBackgroundImage}
					heroTitle={Data.heroTitle}
					heroDescription={Data.heroDescription}
					heroNote={Data.heroNote}
					brandingLogoMobile={Data.heroBrandingLogo}
					brandingLogoAltMobile={Data.heroBrandingLogoAlt}
					heroCustomClass={Data.heroCustomClass}
					signUpSubtitle={Data.heroSignUpSubtitle}
					signUpUrl={Data.heroSignUpUrl}
					showBgOnMobile={true}
					signUpButton={Data.heroSignUpButton}
					linkAccountSubtitle={Data.heroLinkAccountSubtitle}
					linkAccountButton={Data.heroLinkAccountButton}
					brandingLogoDesktop={Data.heroBrandingLogo}
					heroBrandingLogoAltDesktop={Data.heroBrandingLogoAlt}
				/>
				<section className="container">
					<div className="row">
						<div className="col">
							<div dangerouslySetInnerHTML={{ __html: Data.introText }}>
							</div>
							<h2>{Data.pageTitle}</h2>
							<p className="info-wrapper">
								{Data.infoText}
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col mb-medium">
							<p dangerouslySetInnerHTML={{ __html: Data.alternateEntryFormText }}></p>
						</div>
					</div>
					<div className="row sectioned">
						<div className="col pe-small">
							<SignUpForm onSuccess={this.handleSuccess} />
						</div>
						<div className="col small">
							<SignInForm onLoginSuccess={this.handleSuccess} />
						</div>
					</div>
					<div className="row">
						<div className="col mt-medium">
							<p className="disclaimer-component" dangerouslySetInnerHTML={{ __html: Data.entryFormDisclaimer }}></p>
						</div>
					</div>
				</section>
			</main>
		</>
	}
}

SweepsLanding.contextType = BrandContext;

export default withRouter(SweepsLanding);
