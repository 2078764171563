import React from "react";
import { withRouter } from "react-router-dom";
import { updatePartner } from "../api/updatePartner";
import ContentContext from "../common/contexts/content";
import { getUser, saveUser } from "../common/user";
import Hero from "../components/Hero";
import SignUpForm from "../components/SignUpForm";
import SignInForm from "../components/SignInForm";
import Disclaimer from "../components/Disclaimer";

class AATMobile extends React.Component {
	handleSuccess = async () => {
		const { token } = getUser();

		let user = await updatePartner(token, this.props.partnerCode || 'partner13');
		saveUser(user);

		// redirect to success
		this.props.history.replace(`${this.props.match.path}/success`);
	}

	getData() {
		return this.context.landingData;
	}

	render() {
		const Data = this.getData();
		return <>
			<main className="main-content">
				<Hero
					backgroundImage={Data.heroBackgroundImage}
          			showBgOnMobile={Data.showBgOnMobile}
					heroTitle={Data.heroTitle}
					heroNote={Data.heroNote}
					brandingLogoMobile={Data.heroBrandingLogo}
					brandingLogoAltMobile={Data.heroBrandingLogoAlt}
					heroDescription={Data.heroDescription}
					linkAccountSubtitle={Data.heroLinkAccountSubtitle}
					linkAccountButton={Data.heroLinkAccountButton}
					brandingLogoDesktop={Data.heroBrandingLogo}
					heroBrandingLogoAltDesktop={Data.heroBrandingLogoAlt}
				/>
				<section className="container">
					<div className="row">
						<div className="col">
							<h1>{Data.pageTitle}</h1>
							<p className="info-wrapper">
								{Data.infoText}
							</p>
							{
								Data.additionalInfoText && (
									<p className="info-wrapper">
										{Data.additionalInfoText}
									</p>
								)
							}
						</div>
					</div>
					<div className={`row ${Data.showSignInForm && " sectioned"}`}>
						<div className="col">
							<SignUpForm onSuccess={this.handleSuccess} />
						</div>
						{Data.showSignInForm && (
							<div className="col">
								<SignInForm onLoginSuccess={this.handleSuccess} />
							</div>
						)}
					</div>
					{
						Data.disclaimerText && (
							<div className="container">
								<Disclaimer
									disclaimerText={Data.disclaimerText}
								/>
							</div>
						)
					}
					{Data.disclaimerTextAdditional && (
						<div dangerouslySetInnerHTML={{ __html: Data.disclaimerTextAdditional }}></div>
         			 )}
				</section>
			</main>
		</>
	}
}

AATMobile.contextType = ContentContext;

export default withRouter(AATMobile);
