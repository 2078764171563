import React from "react";
import { Brand } from "../../common/constants/constants";
import { isSweepsMca, isSweepsMoes } from "../../common/helpers/handleSubdomains";
import { SignUpData } from "../../data/SignUp.data";
import Joi from "joi";
import { register } from "../../api";
import { saveUser } from "../../common/user";
import { LINK_ACCOUNT_URL } from "../../common/constants/urls";
import { processApiError } from "../../common/helpers/processApiError";
import { processJoiError } from "../../common/helpers/processJoiError";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Recaptcha } from "../Recaptcha";
import BrandContext from "../../common/contexts/brand";

// this forms contains the ZIPCODE field and NO BIRTHDAY
// TODO: Consider using composition instead of creating another component from scratch

const recaptchaAction = 'REGISTER';

class SweepsSignUpForm extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.captchaRef = React.createRef();
    this.state = {
      attemptedSubmit: false,
      formData: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        zipCode: "",
        password: "",
        confirmPassword: "",
        communicationCheckbox: false,
        TOSCheckbox: false,
        recaptcha: "",
      },
      generalErrors: [],
      formErrors: {},
    };
  }

  getData() {
    let brand = Brand;
   
    if (isSweepsMca()) {
      brand = "sweepsmca";
    } else if (isSweepsMoes()) {
      brand = "sweepsmoes";
    }

    return SignUpData[brand];
  }

  componentDidMount() {
    const Data = this.getData();

    this.formSchema = Joi.object({
      firstName: Joi.string().alphanum().required().messages({
        'string.empty': Data.formErrorMessages.firstName.required,
        'string.alphanum': Data.formErrorMessages.firstName.alphanum,
      }),
      lastName: Joi.string().alphanum().required().messages({
        'string.empty': Data.formErrorMessages.lastName.required,
        'string.alphanum': Data.formErrorMessages.lastName.alphanum
      }),
      email: Joi.string().required().email({ minDomainSegments: 2, tlds: false }).messages({
        'string.empty': Data.formErrorMessages.email.required,
        'string.email': Data.formErrorMessages.email.invalid,
      }),
      password: Joi.string().min(8).pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/,
        'password').required().messages({
          'string.empty': Data.formErrorMessages.password.required,
          'string.min': Data.formErrorMessages.password.condition,
          'string.pattern.name': Data.formErrorMessages.password.criteria,
        }),
      confirmPassword: Joi.string().required().valid(Joi.ref('password')).messages({
        'string.empty': Data.formErrorMessages.confirmPassword.required,
        'any.only': Data.formErrorMessages.confirmPassword.match,
      }),
      phone: Joi.string().min(10).pattern(/^[0-9]+$/).required().messages({
        'string.empty': Data.formErrorMessages.phone.required,
        'string.min': Data.formErrorMessages.phone.minLength,
        'string.pattern.base': Data.formErrorMessages.phone.invalid,
      }),
      zipCode: Joi.string().required().messages({
        'string.empty': Data.formErrorMessages.zipCode.required,
      }),
      communicationCheckbox: Joi.boolean(),
      TOSCheckbox: Joi.boolean().valid(true).messages({
        'any.only': Data.formErrorMessages.checkbox.required,
      }),
      recaptcha: Joi.string().required().messages({
        'string.empty': Data.formErrorMessages.recaptcha.required,
      }),
    });
  }

  updateFormField(field, value) {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [field]: value,
        },
      }
    });
  }

  updateFormError(field, value) {
    this.setState(prevState => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      }
    });
  }

  handleValueChange = (e) => {
    this.updateFormField(e.target.name, e.target.value);
  };

  handleCheckedChange = (e) => {
    this.updateFormField(e.target.name, e.target.checked);
  };

  handleRecaptchaSuccess = (token) => {
    const field = 'recaptcha';
    this.updateFormField(field, token);
    this.validateFormField(field)
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const Data = this.getData();
    const isValid = this.validateForm();
    this.setState({ attemptedSubmit: true });

    if (isValid) {
      try {
        const user = await register(this.state.formData, this.state.formData.recaptcha, recaptchaAction);
        saveUser(user);

        // use custom success handler if available; otherwise default to
        // redirecting to link account
        if (this.props.onSuccess) {
          this.props.onSuccess();
        } else {
          this.props.history.replace(LINK_ACCOUNT_URL);
        }

      } catch (err) {
        const result = processApiError(err, Data.apiFieldErrorMessages);
        this.setState({
          generalErrors: result.generalMessages,
          formErrors: result.fieldMessages,
        });

        // Reset the captcha only in case of error from the backend since on success
        // the page with be changed.
        this.captchaRef.current.reset();
        this.updateFormField('recaptcha', '');
      }
    }
  };

  validateFormField = (field) => {
    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    let errorMessage = "";

    if (result.error) {
      for (const error of result.error.details) {
        const key = error.path[0];

        // ignore errors for other fields; this approach is chosen over extracting a sub-schema
        // because we use a joi ref for the confirm password
        if (key === field) {
          errorMessage = error.message;
        }
      }
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    // reset form errors

    this.setState({
      generalErrors: [],
      formErrors: {}
    });

    const result = this.formSchema.validate(this.state.formData, { abortEarly: false });
    if (result.error) {
      const formErrors = processJoiError(result.error);

      const formElements = Array.from(this.formRef.current.querySelectorAll('input, select'));
      let ariaTargets = formElements.map(el => ({
        field: el.name,
        target: el,
      }));

      // manually add recaptcha target since it's managed by a library
      ariaTargets = [...ariaTargets, {
        field: 'recaptcha',
        target: this.formRef.current.querySelector('.captcha-wrapper iframe'),
      }];

      for (const obj of ariaTargets) {
        if (formErrors[obj.field]) {
          obj.target.focus();
          break;
        }
      }
      this.setState({ formErrors });
      return false;
    }
    return true;
  };

  render() {
    const Data = this.getData();

    return (
      <>
        {Data.formTitle && (
          <h3>{Data.formTitle}</h3>
        )}
        {Data.formDescription && (
          <p className="info-wrapper">{Data.formDescription}</p>
        )} 
        <form
          ref={this.formRef}
          onSubmit={this.handleSubmit}
          className={[
            "form-wrapper",
            this.state.attemptedSubmit === true ? "show-errors" : "",
          ].join(" ")}
          id="registerForm"
        >
          {Data.SignInLinkText && (
            <div className="row">
              <div className="col">
                <p className="info-wrapper">
                  {Data.CTASignIn}{" "}
                  <Link to={`${LINK_ACCOUNT_URL}`}>
                    {Data.SignInLinkText}
                  </Link>
                </p>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col">
              <div className="error-wrapper">
                {this.state.generalErrors.map((message, index) => {
                  return <span key={index} className="error">{message}</span>
                })}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="firstName">{Data.firstNameLabel}</label>
                <br />
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.firstName)}
                  aria-describedby="firstNameError"
                  className={[
                    this.state.formErrors.firstName ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="firstNameError" className="error-label">
                  {this.state.formErrors.firstName}
                </span>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="lastName">{Data.lastNameLabel}</label>
                <br />
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.lastName)}
                  aria-describedby="lastNameError"
                  className={[
                    this.state.formErrors.lastName ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="lastNameError" className="error-label">
                  {this.state.formErrors.lastName}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="email">{Data.emailLabel}</label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.email)}
                  aria-describedby="emailError"
                  className={[
                    this.state.formErrors.email ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="emailError" className="error-label">
                  {this.state.formErrors.email}
                </span>
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="password">{Data.passwordLabel}</label>
                <br />
                <input
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.password)}
                  aria-describedby="passwordError"
                  className={[
                    this.state.formErrors.password ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="passwordError" className="error-label">
                  {this.state.formErrors.password}
                </span>
                <span>{Data.passwordInfo}</span>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="confirmPassword">{Data.confirmPasswordLabel}</label>
                <br />
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.confirmPassword)}
                  aria-describedby="confirmPasswordError"
                  className={[
                    this.state.formErrors.confirmPassword ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="confirmPasswordError" className="error-label">
                  {this.state.formErrors.confirmPassword}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="phone">{Data.phoneLabel}</label>
                <br />
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.phone)}
                  aria-describedby="phoneError"
                  className={[
                    this.state.formErrors.phone ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="phoneError" className="error-label">
                  {this.state.formErrors.phone}
                </span>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="phone">{Data.zipCodeLabel}</label>
                <br />
                <input
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.zipCode)}
                  aria-describedby="zipCodeError"
                  className={[
                    this.state.formErrors.zipCode ? 'invalid' : ''
                  ]}
                  onChange={this.handleValueChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <span id="zipCodeError" className="error-label">
                  {this.state.formErrors.zipCode}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <input
                  type="checkbox"
                  id="communicationCheckbox"
                  name="communicationCheckbox"
                  onChange={this.handleCheckedChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <label htmlFor="communicationCheckbox">
                  {Data.communicationCheckboxText}
                </label>
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="TOSCheckbox"
                  name="TOSCheckbox"
                  aria-required="true"
                  aria-invalid={Boolean(this.state.formErrors.TOSCheckbox)}
                  aria-describedby="TOSCheckboxError"
                  defaultChecked={this.state.TOSCheckbox}
                  onChange={this.handleCheckedChange}
                  onBlur={e => this.validateFormField(e.target.name)}
                />
                <label htmlFor="TOSCheckbox">
                  {Data.termsHTML ? (
                    <span dangerouslySetInnerHTML={{__html: Data.termsHTML}}></span>
                  ) : (
                    <>
                      {Data.termsPrefix}
                      {isSweepsMca() ? 
                        <>
                          McAlister's Deli <a href={Data.termsLinkURL} target="_blank">
                          {Data.termsLinkText}    
                          </a>, the <a href={Data.rewardsTermsLinkURL} target="_blank">
                            {Data.rewardsTermsLinkText}    
                          </a> and the use of my personal information as explained in the <a href={Data.privacyTermsLinkURL} target="_blank">
                            {Data.privacyTermsLinkText}    
                          </a>
                        </> 
                        : 
                        <a href={Data.termsLinkURL} target="_blank">
                          {Data.termsLinkText}    
                        </a>
                      }
                    </>
                  )}
                </label>
                <span id="TOSCheckboxError" className="error-label">
                  {this.state.formErrors.TOSCheckbox}
                </span>
              </div>
            </div>
          </div>
          <div className="row v-align-center mt-small">
            <div className="col">
              <div className="captcha-wrapper">
                <Recaptcha action={recaptchaAction} onSuccess={this.handleRecaptchaSuccess} ref={this.captchaRef} />
              </div>
              <span className="error-label">
                {this.state.formErrors.recaptcha}
              </span>
            </div>
            <div className="col">
              <div className={[
                "proceed-wrapper",
                Data.joinSubtitle ? "" : "h-align-end"
              ].join(" ")}>
                <button className="primary-button">
                  {Data.joinButton}
                </button>
                {Data.joinSubtitle && (
                  <span>
                    {Data.joinSubtitle} <br />{Data.joinSubtitleSecond}
                  </span>
                )}
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

SweepsSignUpForm.contextType = BrandContext;

export default withRouter(SweepsSignUpForm);
