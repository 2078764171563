import { ApiError } from "./exception";

export async function register(input, recaptchaToken, recaptchaAction) {
  const response = await fetch(`${process.env.REACT_APP_API}/api/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      firstName: input.firstName,
      lastName: input.lastName,
      email: input.email,
      password: input.password,
      passwordConfirmation: input.confirmPassword,
      month: input.month,
      day: input.day,
      phone: input.phone,
      marketingEmailSubscription: input.communicationCheckbox,
      termsAndConditions: input.TOSCheckbox,
      zipCode: input.zipCode,
      recaptcha: {
        token: recaptchaToken,
        action: recaptchaAction,
      }
    })
  });

  const obj = await response.json();

  if (response.ok) {
    return obj;
  }

  if (obj.error) {
    throw new ApiError(obj.error);
  }

  throw new Error(obj);
}