const userStorageKey = 'u';

export function getUser() {
  const val = sessionStorage.getItem(userStorageKey);
  if (!val) {
    return null;
  }

  return JSON.parse(val);
}

export function saveUser(user) {
  sessionStorage.setItem(userStorageKey, JSON.stringify(user));
}

export function clearUser() {
  sessionStorage.removeItem(userStorageKey);
}

export function isUserLinked(user, expectedPartner) {
  if (!user) {
    return false;
  }

  return (user.partners && user.partners.length > 0 && user.partners.includes(expectedPartner));
}