export default {
  tools: [
    {
      href: "https://www.cinnabon.com/company-info",
      text: "About Us"
    },
    {
      href: "https://development.focusbrands.com/",
      text: "Franchise",
      thirdParty: true
    },
    {
      href: "https://www.cinnabon.com/products",
      text: "Grocery"
    },
    {
      href: "https://www.cinnabon.com/fundraising",
      text: "Fundraising"
    },
    {
      href: "https://www.cinnabon.com/company-info/careers",
      text: "Carrers"
    },
    {
      href: "https://www.cinnabon.com/-/media/cinnabon/nutrition/nutritional-guide-42023.pdf?v=1&d=20230418T084920Z",
      text: "Nutrition"
    },
    {
      href: "https://www.cinnabon.com/-/media/cinnabon/allergens/allergen-chart_website.pdf?v=1&d=20221219T085021Z",
      text: "Allergens"
    },
    {
      href: "https://www.cinnabon.com/recipes",
      text: "Recipes"
    },
    {
      href: "https://www.cinnabon.com/faq",
      text: "FAQ"
    },
    {
      href: "https://www.cinnabon.com/contact-us",
      text: "Contact Us",
      thirdParty: true
    },
    {
      href: "https://www.cinnabon.com/newsroom",
      text: "Newsroom"
    },
  ],
  copyrightFetch: "©2024 Fetch Rewards, Inc., All rights reserved. Fetch and the Fetch logo are trademarks of Fetch Rewards, Inc.​",
  copyrightText: "© 2024 Cinnabon® Franchisor SPV LLC. All rights reserved. ​",
  copyright: [
    {
      href: "https://www.cinnabon.com/legal",
      text: "Legal"
    },
    {
      href: "https://www.cinnabon.com/privacy",
      text: "Privacy"
    },
    {
      href: "https://www.cinnabon.com/caprivacy",
      text: "CA Privacy"
    },
    {
      hasTruyoPrivacyOptions: true,
      href: "#",
      id: "cookieSettingButton",
      customClass: "cookieSettingButton",
      text: "Your Privacy Choices"
    },
    {
      href: "https://www.focusbrands.com/accessibility/cinnabon",
      text: "Accessibility",
      customClass: "fcs-third-party"
    },
    {
      href: "https://www.focusbrands.com/animal-welfare",
      text: "Animal Welfare",
      customClass: "fcs-third-party"
    },
    {
      href: "https://www.cinnabon.com/sitemap",
      text: "Sitemap"
    },
  ]
}