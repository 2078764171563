import React from "react";
import { Link } from "react-router-dom";
import { LINK_ACCOUNT_URL } from "../common/constants/urls";
import BrandContext from "../common/contexts/brand";
import { AppURLData } from "../data/AppUrl.data";
import { clearUser } from "../common/user";
import Card from "../components/Card";
import Hero from "../components/Hero";
import Spotlight from "../components/Spotlight";
import Notices from "../components/Notices";
import DynamicLink from "../components/DynamicLink";
import { LandingData } from "../data/Landing.data";
import Disclaimer from "../components/Disclaimer";
import { Brand, HAS_FUEL_PARTNER } from "../common/constants/constants";
import { isFetchMca } from "../common/helpers/isFetchMca";
import { isFetchSl } from "../common/helpers/isFetchSl";
import { isFuelJa, isFuelJaLto } from "../common/helpers/isFuelJa";
import SignUpForm from "../components/SignUpForm";
import SignInForm from "../components/SignInForm";


class Landing extends React.Component {
  componentDidMount() {
    clearUser();
  }

  render() {
    let brand = Brand;

    if (isFetchMca()) {
      brand = "fetchmca";
    } else if (isFetchSl()) {
      brand = "fetchsl";
    } else if (isFuelJaLto()) {
      brand = "fueljalto";
    } else if (isFuelJa()) {
      brand = "fuelja";
    }

    const Data = LandingData[brand];
    const AppURL = AppURLData[brand];
    return (
      <main className="main-content">
        <Hero
          backgroundImage={Data.heroBackgroundImage}
          showBgOnMobile={Data.showBgOnMobile}
          heroTitle={Data.heroTitle}
          brandingLogoMobile={Data.heroBrandingLogo}
          brandingLogoAltMobile={Data.heroBrandingLogoAlt}
          heroDescription={Data.heroDescription}
          signUpSubtitle={Data.heroSignUpSubtitle}
          signUpUrl={Data.heroSignUpUrl}
          signUpButton={Data.heroSignUpButton}
          linkAccountSubtitle={Data.heroLinkAccountSubtitle}
          linkAccountButton={Data.heroLinkAccountButton}
          brandingLogoDesktop={Data.heroBrandingLogo}
          heroBrandingLogoAltDesktop={Data.heroBrandingLogoAlt}
        />

        <div className="mobile-primary-bg">
          <div className="container">
            {Data.customHTML && (
              <div className={Data.customHTMLClass} dangerouslySetInnerHTML={{ __html: Data.customHTML }}></div>
            )}
            {Data.introText && (
              <p className="section-subtitle secondary-style">{Data.introText}</p>
            )}
            <h2 className="section-title small">{Data.stepTitle}</h2>
            {Data.stepSubtitle && (
              <p className="section-subtitle secondary-style">{Data.stepSubtitle}</p>
            )}
            {Data.stepDescription && (
                <div className="landing-head" dangerouslySetInnerHTML={{ __html: Data.stepDescription }}></div>
            )}
            {!Data.hideSteps &&
            <div className="row">
              <div className="col">
                <Card
                  imageURL={Data.step1.imageURL}
                  imageAlt={Data.step1.imageAlt}
                  title={Data.step1.title}
                  theme="secondary-style"
                >
                  <p className="description">
                    {Data.step1.showSignUpLink && (
                      <DynamicLink linkData={Data.signUp} />
                    )}
                    {Data.step1.line1.postlinkText}
                    {HAS_FUEL_PARTNER && <div dangerouslySetInnerHTML={{ __html: Data.stepCustomHTML }}></div>}
                  </p>
                  {Data.step1.line2.prelinkText && (
                    <p className="description">
                      {Data.step1.line2.prelinkText} <DynamicLink linkData={Data.signIn} />
                    </p>
                  )}
                </Card>
              </div>
              <div className="col">
                <Card
                  imageURL={Data.step2.imageURL}
                  imageAlt={Data.step2.imageAlt}
                  title={Data.step2.title}
                  theme="secondary-style"
                >
                  <p className="description">
                    {Data.step2.line1.prelinkText}
                    &nbsp;
                    {Data.step2.showLinkAccountLink && (
                      <DynamicLink linkData={Data.linkAccount} />
                    )}
                  </p>
                  {Data.step2.line2.prelinkText && (
                    <p className="description">
                      {Data.step2.line2.prelinkText}<br /><DynamicLink linkData={Data.joinAARP} />
                    </p>
                  )}
                </Card>
              </div>
              <div className="col">
                <Card
                  imageURL={Data.step3.imageURL}
                  imageAlt={Data.step3.imageAlt}
                  title={Data.step3.title}
                  theme="secondary-style"
                >
                  <p className="description">
                    {Data.step3.line1.prelinkText}
                    {Data.step3.showAppLink && (
                      <>
                        {Data.downloadSection.URL ? <DynamicLink linkData={Data.downloadSection} /> : Data.downloadSection.text}
                      </>
                    )}
                    {Data.step3.line1.postlinkText}
                  </p>
                  {Data.step3.line2.text && (
                    <p className="description">
                      {Data.step3.line2.text}
                    </p>
                  )}

                </Card>
              </div>
            </div>
            }

            {this.props.showForms && (
              <div className={"row sectioned"}>
                <div className="col">
                  <SignUpForm onSuccess={this.props.redirect} />
                </div>
                <div className="col">
                  <SignInForm onLoginSuccess={this.props.redirect} />
                </div>
              </div>
            )}
          </div>
        </div>

        {Data.showButtonListSection && (
          <section className="primary-bg pt-medium pb-medium">
            <div className="container">
              <h2 className="section-title small">{Data.linkTitle}</h2>
              <ul className="button-list-component">
                <li className="button-wrapper">
                  <span className="info">{Data.signUpSubtitle}</span>
                  <Link className="primary-button" to={`/sign-up`}>{Data.signUpButton}</Link>
                </li>
                <li className="button-wrapper">
                  <span className="info">{Data.linkAccountSubtitle}</span>
                  <Link className="secondary-button" to={`${LINK_ACCOUNT_URL}`}>{Data.linkAccountButton}</Link>
                </li>
                <li className="button-wrapper">
                  <span className="info">{Data.joinSubtitle}</span>
                  <a className="primary-button" href={Data.joinButton.URL} target={Data.joinButton.target}>{Data.joinButton.text}
                  </a>
                </li>
              </ul>
            </div>
          </section>
        )}

        {Data.showBenefitsSection && (
          <div className="container">
            <h2 className="section-title mt-medium">{Data.benefitsTitle}</h2>
            <div className="row three-col-wrapper">
              {Data.benefits.map((benefit, index) => {
                return (
                  <div
                    className="col"
                    key={index}
                  >
                    <Card
                      imageURL={benefit.imageURL}
                      imageAlt={benefit.imageAlt}
                      title={benefit.title}
                      description={benefit.description}
                      note={benefit.note}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {Data.showSpotlightSection && (
          <section className="primary-bg mt-medium mb-large">
            <div className="container">
              <Spotlight
                id={Data.spotlightID}
                title={Data.spotlightTitle}
                description={Data.spotlightDescription}
                imageURL={Data.spotlightImageURL}
                imageAlt={Data.spotlightImageAlt}
                appStoreImage={AppURL.appStoreImage}
                appStoreURL={AppURL.appStoreURL}
                appStoreAlt={AppURL.appStoreAlt}
                googlePlayImage={AppURL.googlePlayImage}
                googlePlayURL={AppURL.googlePlayURL}
                googlePlayAlt={AppURL.googlePlayAlt}
                showLinks={true}
                theme="secondary-styling"
              />
            </div>
          </section>
        )}
        {Data.showNoticesSection && (
          <div className="mobile-primary-bg">
            <section className="pt-medium pb-medium">
              <div className="container">
                <Notices
                  id1={Data.notices1ID}
                  title1={Data.notices1Title}
                  description1={Data.notices1Description}
                  image1URL={Data.notices1ImageURL}
                  image1Alt={Data.notices1ImageAlt}
                  id2={Data.notices2ID}
                  title2={Data.notices2Title}
                  description2={Data.notices2Description}
                  image2URL={Data.notices2ImageURL}
                  image2Alt={Data.notices2ImageAlt}
                />
              </div>
            </section>
          </div>
        )}



        <div className="container">
          <Disclaimer
            disclaimerText={Data.rewardsDisclaimer}
          />
          <Disclaimer
            disclaimerText={Data.disclaimerText}
          />
        </div>
      </main>
    );
  }

}

Landing.contextType = BrandContext;

export default Landing;
