import React from "react";
import BrandContext from "../common/contexts/brand";
import { SignInData } from "../data/SignIn.data";
import { Brand } from "../common/constants/constants";
import SignInForm from "../components/SignInForm";

const Data = SignInData[Brand];

class SignIn extends React.Component {
  handleSuccess = () => {
		this.props.history.replace(this.props.successPath);
	}

  render() {
    return (
        <main className="main-content">
          <section className="container">
            <div className="row mb-medium">
              <div className="col">
                <h1>{Data.pageTitle}</h1>
              </div>
            </div>
            <SignInForm onLoginSuccess={this.props.onLoginSuccess} />
          </section>
        </main>
    );
  }
}

SignIn.contextType = BrandContext;

export default SignIn;