import React from 'react';

export function EvalBrand(param) {
    switch(param) {
        case "moes":
        case "schlotzskys":
        case "auntieannes":
        case "mcalisters":
        case "carvel":
        case "jamba":
        case "fetchmca":
        case "cinnabon":
        // case "fetchcimc":
        // case "fetchcimo":
        case "fetchmoci":
        case "fetchmcci":
        case "fuelja":
            return param;
        default:
            return "moes";
    }
}

const BrandContext = React.createContext();

export default BrandContext;
