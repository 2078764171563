import React from 'react';

// Keep track globally if the recaptcha script has been loaded
let scriptLoaded = null;

// How often, in milliseconds, a check is ran to check for the script
let scriptCheckFrequency = 100;

export class Recaptcha extends React.Component {
  constructor(props) {
    super(props);

    if (null === scriptLoaded) {
      scriptLoaded = new Promise((resolve, reject) => {
        // check every few milliseconds if recaptcha script has loaded
        this.intervalId = setInterval(() => {
          if (window.grecaptcha && window.grecaptcha.enterprise) {
            clearInterval(this.intervalId);
            window.grecaptcha.enterprise.ready(() => resolve());
          }
        }, scriptCheckFrequency);
      });
    }

    this.containerRef = React.createRef();
    this.widgetId = null;
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    /**
     * `grecaptcha.enterprise.render` appends a `div` at the bottom of the body which contains an iframe
     * with the recaptcha challenge. Since there is no explicit way to remove this `div` through the recaptcha
     * API, this piece of code attempts to locate the div and remove it.
     */
    if (this.widgetId !== null) {
      const iframe = document.body.querySelector(`[title="recaptcha challenge expires in two minutes"]`);
      // find the iframe, find the second div, find the first div and remove it.
      if (iframe) {
        let parent = iframe.parentElement;
        if (parent && parent.tagName === 'DIV') {
          parent = parent.parentElement;
          if (parent && parent.tagName === 'DIV') {
            parent.remove();
          }
        }
      }
    }
  }

  componentDidMount() {
    // render widget once script is loaded
    scriptLoaded.then(() => {
      window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: this.props.action})
      .then((token) => {
        if (this.props.onSuccess) {
          this.props.onSuccess(token);
        }
      });
    });
  }

  reset = () => {
    if (this.widgetId !== null) {
      window.grecaptcha.enterprise.reset(this.widgetId);
    }
  }

  verifyCallback = (response) => {
    if (this.props.onSuccess) {
      this.props.onSuccess(response);
    }
  }

  render() {
    return <div ref={this.containerRef} />;
  }
}