import { Route, Switch, Redirect } from "react-router-dom";
import SignUp from "./pages/SignUp";
import FetchSignUp from "./pages/FetchSignUp";
import Linking from "./pages/Linking";
import SuccessLanding from "./pages/SuccessLanding";
import Landing from "./pages/Landing";
import Recovery from "./pages/Recovery";
import React from "react";
import ResetLanding from "./pages/ResetLanding";
import NotFound from "./pages/NotFound";
import { LINK_ACCOUNT_URL, SUCCESS_URL, LANDING_URL, RESET_LANDING_URL } from "./common/constants/urls";
import { LinkedUserRoute } from "./components/LinkedUserRoute";
import { AnonymousRoute } from "./components/AnonymousRoute";
import { Brand, CROSS_SELL_BRANDS, HAS_FETCH_PARTNER, HAS_FUEL_PARTNER, HAS_SHOW_FORM, PARTNERS, POLL_ID, getAaMutPaths } from "./common/constants/constants";
import { isChannelsSubdomain, isSweepsSubdomain } from "./common/helpers/handleSubdomains";
import SweepsLanding from "./pages/SweepsLanding";
import LandingPoll from "./pages/LandingPoll";
import ChannelsLanding from "./pages/Channels/ChannelsLanding";
import SweepsSignin from "./pages/Sweeps/SweepsSignin";
import SweepsSignup from "./pages/Sweeps/SweepsSignup";
import SweepsEntry from "./pages/SweepsEntry";
import SweepsSuccessLanding from "./pages/SweepsSuccessLanding";
import SweepsTerms from "./pages/SweepsTerms";
import { getBrandPaths } from "./common/constants/crossSell";
import CrossSellSignUp from "./pages/CrossSellSignUp";
import FRLinking from "./pages/FuelRewards/Linking";
import AAMut from "./pages/AAMut";
import AATMobile from "./pages/AATMobile";
import AAMutOffer from "./pages/AAMutOffer";
import AAOfferExpired from "./pages/AAOfferExpired";
import { isFuelJaLto } from "./common/helpers/isFuelJa";

class AppRoutes extends React.Component {
  render() {
    // Sweeps
    if (isSweepsSubdomain()) {
      if (Brand === "mcalisters") {
        return (
          <>
            <Route
              render={({ match }) => {
                return (
                  <Switch>
                    <Route exact path={match.path} render={() => <Redirect to="/drpepperbig12" />} />
                    {/*
                      TODO:
                      To revisit the routes logic when we know how most of the Sweeps pages should work when the promotions are closed
                      We possibly need an *env var* like "SWEEPS_MCA_CLOSED=true"
                    */}
                    <Route exact path="/drpepperbig12" render={() => {
                      return <SweepsLanding successPath="/drpepperbig12/sweeps-entry" />;
                    }} />
                    <Route exact path="/drpepperbig12/sweeps-entry" render={() => {
                      return <SweepsEntry partner="partner6" successPath="/drpepperbig12/success" />;
                    }} />
                    <Route exact path="/drpepperbig12/rules" component={SweepsTerms} />
                    <Route exact path="/drpepperbig12/success" component={SweepsSuccessLanding} />

                    {/* Temporarily hidden, uncomment after the campaign has ended */}
                    {/* <Route exact path="/drpepperbig12/closed" component={SweepsClosed} /> */}
                    <Route path="*" component={NotFound} />
                  </Switch>
                )
              }}
            />
          </>
        )
      } else if (Brand === "jamba") {
        return (
          <>
            <Route
              render={({ match }) => {
                return (
                  <Switch>
                    <Route exact path={match.path} render={() => <Redirect to="/concert" />} />
                    <Route exact path="/concert" render={() => {
                      return <SweepsLanding successPath="/concert/sweeps-entry" />;
                    }} />
                    <Route exact path="/concert/sweeps-entry" render={() => {
                      return <SweepsEntry partner="partner7" successPath="/concert/success" />;
                    }} />
                    <Route exact path="/concert/rules" component={SweepsTerms} />
                    <Route exact path="/concert/success" component={SweepsSuccessLanding} />
                    <Route path="/concert/recovery" component={Recovery} />
                    <Route path={RESET_LANDING_URL} component={ResetLanding} />
                    <Route path="*" component={NotFound} />
                  </Switch>
                )
              }}
            />
          </>
        )
      }

      return (
        <Route
          render={({ match }) => {
            return (
              <Switch>
                <Route exact path={match.path} render={() => <Redirect to="/soundsofsummer" />} />
                <Route exact path="/soundsofsummer" component={SweepsSignin} />
                <Route exact path="/soundsofsummer/rewards-signup" component={SweepsSignup} />
                <Route exact path="/soundsofsummer/sweeps-entry" component={SweepsEntry} />

                <Route exact path="/soundsofsummer" render={() => {
                  return <SweepsLanding successPath="/soundsofsummer/sweeps-entry" />;
                }} />
                <Route exact path="/soundsofsummer/sweeps-entry" render={() => {
                  return <SweepsEntry partner="partner5" successPath="/soundsofsummer/success" />;
                }} />

                <Route exact path="/soundsofsummer/success" component={SweepsSuccessLanding} />
                <Route exact path="/soundsofsummer/rules" component={SweepsTerms} />
                <Route path="*" component={NotFound} />
              </Switch>
            )
          }}
        />
      )
      // Pool LP (carvel vote)
    } else if (POLL_ID) {
      return (
        <Switch>
          <Route exact path="/" component={LandingPoll} />
          <Route path="" component={NotFound} />
        </Switch>
      )
      // Channels
    } else if (isChannelsSubdomain()) {
      return (
        <Switch>
          <Route exact path="/" component={ChannelsLanding} />
          <Route exact path="/success" component={SuccessLanding} />
          <Route path="*" component={NotFound} />
        </Switch>
      )
    }

    // AARP & Fetch
    return (
      <Switch>
        <Route exact path="/" render={() => <Redirect to={LANDING_URL} />} />
        <Route path={LANDING_URL} exact render={({ history }) => {
          return <Landing showForms={HAS_SHOW_FORM} redirect={() => {
            history.push(LINK_ACCOUNT_URL);
          }} />;
        }} />
        <AnonymousRoute path="/sign-up" component={SignUp} redirectPath={LANDING_URL} />
        <Route path={LINK_ACCOUNT_URL} component={Linking} />
        <LinkedUserRoute path={SUCCESS_URL} component={SuccessLanding} expectedPartner={PARTNERS.Partner1} />
        <Route path="/recovery" component={Recovery} />
        <Route path={RESET_LANDING_URL} component={ResetLanding} />

        {CROSS_SELL_BRANDS && <Route
          path={getBrandPaths()}
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact component={CrossSellSignUp} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path={`${match.path}/recovery`} component={Recovery} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {HAS_FETCH_PARTNER && <Route
          path="/fetch"
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact component={FetchSignUp} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {(HAS_FUEL_PARTNER && !isFuelJaLto()) && <Route
          path="/fuelrewards"
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact render={({ history }) => {
                  return <Landing showForms={true} redirect={() => {
                    history.push("/fuelrewards/link-account");
                  }} />;
                }} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path={`${match.path}/link-account`} render={({ history }) => {
                  return <FRLinking history={history} partnerId="partner8" />;
                }} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {HAS_FUEL_PARTNER && !isFuelJaLto() && <Route
          path="/fuelrewards"
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact render={({ history }) => {
                  return <Landing showForms={true} redirect={() => {
                    history.push("/fuelrewards/link-account");
                  }} />;
                }} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path={`${match.path}/link-account`} render={({ history }) => {
                  return <FRLinking history={history} partnerId="partner8" />;
                }} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {HAS_FUEL_PARTNER && isFuelJaLto() && <Route
          path="/fuelrewardslto"
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact render={({ history }) => {
                  return <Landing showForms={true} redirect={() => {
                    history.push("/fuelrewardslto/link-account");
                  }} />;
                }} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path={`${match.path}/link-account`} render={({ history }) => {
                  return <FRLinking history={history} partnerId="partner8" />;
                }} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {Brand === "auntieannes" && <Route
          path={"/mutggenroll"}
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact component={AAMut} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {Brand === "auntieannes" && <Route
          path={getAaMutPaths()}
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact component={AAMutOffer} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path={`${match.path}/expired`} component={AAOfferExpired} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {Brand === "auntieannes" && <Route
          path={"/tmobiletuesdaysoct22"}
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact render={({ history }) => {
                  return <AATMobile history={history} partnerCode="partner21" />;
                }} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path={`${match.path}/recovery`} component={Recovery} />
                <Route path={`${match.path}/reset-landing`} component={ResetLanding} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {Brand === "auntieannes" && <Route
          path={"/tmobiletuesdaysaug27"}
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact render={({ history }) => {
                  return <AATMobile history={history} partnerCode="partner20" />;
                }} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path={`${match.path}/recovery`} component={Recovery} />
                <Route path={`${match.path}/reset-landing`} component={ResetLanding} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {Brand === "moes" && <Route
          path={"/tmobiletuesdaysnov12"}
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact component={AATMobile} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path={`${match.path}/recovery`} component={Recovery} />
                <Route path={`${match.path}/reset-landing`} component={ResetLanding} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {Brand === "schlotzskys" && <Route
          path={"/tmobiletuesdayssept10"}
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact render={({ history }) => {
                  return <AATMobile history={history} partnerCode="partner18" />;
                }} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {Brand === "mcalisters" && <Route
          path={"/tmobiletuesdayssept24"}
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact render={({ history }) => {
                  return <AATMobile history={history} partnerCode="partner19" />;
                }} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path={`${match.path}/recovery`} component={Recovery} />
                <Route path={`${match.path}/reset-landing`} component={ResetLanding} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {Brand === "cinnabon" && <Route
          path={"/tmobiletuesdaysoct15"}
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact render={({ history }) => {
                  return <AATMobile history={history} partnerCode="partner18" />;
                }} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path={`${match.path}/recovery`} component={Recovery} />
                <Route path={`${match.path}/reset-landing`} component={ResetLanding} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        {Brand === "jamba" && <Route
          path={"/tmobiletuesdayssept3"}
          render={({ match }) => {
            return (
              <Switch>
                <Route path={match.path} exact component={AATMobile} />
                <Route path={`${match.path}/success`} component={SuccessLanding} />
                <Route path="" component={NotFound} />
              </Switch>
            );
          }}
        />}

        <Route path="" component={NotFound} />
      </Switch>
    )
  }
}

export default AppRoutes;