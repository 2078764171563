import { ApiError } from "./exception";

export async function updatePartner(accessToken, partnerProgram, memberId, externalId) {
  const response = await fetch(`${process.env.REACT_APP_API}/api/partner`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      partnerProgram,
      memberId,
      externalId,
     })
  });

  const obj = await response.json();

  if (response.ok) {
    return obj;
  }

  if (obj.error) {
    throw new ApiError(obj.error);
  }

  throw new Error(obj);
}