import React from "react";
import { withRouter } from "react-router-dom";
import { Brand, CROSS_SELL_BRANDS } from "../common/constants/constants";
import ContentContext from "../common/contexts/content";
import {
  isChannelAa,
  isChannelsSubdomain,
  isSweepsSubdomain,
} from "../common/helpers/handleSubdomains";
import { isFetchCi } from "../common/helpers/isFetchCi";
import { isFetchSl } from "../common/helpers/isFetchSl";
import { FetchCiFooter } from "./Footer/FetchCiFooter";
import { isCarvelPoll } from "../common/helpers/isCarvelPoll";
import CarvelPollFooter from "./Footer/CarvelPollFooter";
import SweepsMoesFooter from "./Footer/SweepsMoesFooter";
import SweepsMcaFooter from "./Footer/SweepsMcaFooter";
import { SchFooter } from "./Footer/SchFooter";
import { isFetchCiMc } from "../common/helpers/isFetchCiMc";
import { isFetchCiMo } from "../common/helpers/isFetchCiMo";
import { isFetchAaSl } from "../common/helpers/isFetchAaSl";
import ChannelsAaFooter from "./Footer/ChannelsAaFooter";


class Footer extends React.Component {
  render() {
    const FooterContentData = this.context.footerData;

    if (isFetchCi() || isFetchCiMc() || isFetchCiMo()) {
      return (
        <FetchCiFooter />
      )
    } else if (isCarvelPoll()) {
      return (
        <CarvelPollFooter />
      )
    } else if (isChannelAa() || isFetchAaSl() || window.location.pathname.startsWith("/csaun")) {
      return (
        <ChannelsAaFooter />
      )
    } else if (isSweepsSubdomain()) {
      if (Brand === "mcalisters") {
        return (
          <>
            <SweepsMcaFooter />
          </>
        );
      } else if (Brand === "moes") {
        return <SweepsMoesFooter />;
      }
    } else if (Brand === "schlotzskys" && window.location.pathname.startsWith("/cssch")) {
      return <SchFooter />
    }

    return (
      <footer className={`main-footer ${isFetchSl() ? 'fetchsl-footer' : ''}`}>
        <div className="container content-wrapper">
          <span className="footer-text" dangerouslySetInnerHTML={{ __html: FooterContentData.copyright }}></span>
          <ul className="footer-link-list">
            {FooterContentData.footerLinks.map((link, index) => {
              if (link.hasTruyoPrivacyOptions) {
                return (
                  <li key={index}>
                    <a
                      id="cookieSettingButton"
                      className="cookieSettingButton text-decoration-underline"
                      href="#"
                      style={{}}
                    >
                      YOUR PRIVACY CHOICES
                      <span className="privacy-icon">
                        <img src="/common/privacy-icon.svg" />
                      </span>
                    </a>
                  </li>
                );
              }

              return (
                <li key={index}>
                  <a href={link.URL} target={link.target}>
                    {link.text}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </footer>
    );
  }
}

Footer.contextType = ContentContext;

export default withRouter(Footer);
